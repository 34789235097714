import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFormOptions } from '@ngx-formly/core';
import { IFormSubmitPayload, IFormlyCustomFormDialogConfig } from '@rcg/core/models';
import { fullscreenDialog } from '@rcg/standalone/utils/dialog-utils';

@Component({
  selector: 'rcg-formly-custom-form-dialog',
  templateUrl: './formly-custom-form-dialog.component.html',
  styleUrls: ['./formly-custom-form-dialog.component.scss'],
})
export class FormlyCustomFormDialogComponent implements AfterViewInit {
  @ViewChild('dialog', { read: ElementRef }) dialog!: ElementRef<HTMLElement>;

  readonly formOptions: FormlyFormOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogConfig: IFormlyCustomFormDialogConfig,
    public dialogRef: MatDialogRef<FormlyCustomFormDialogComponent>,
  ) {
    this.formOptions = {
      ...dialogConfig.formOptions,
      formState: {
        ...this.dialogConfig,
        ...dialogConfig.formOptions.formState,
      },
    };
  }

  ngAfterViewInit(): void {
    if (this.dialogConfig.openFullscreen) {
      fullscreenDialog(this.dialog.nativeElement);
    }
  }

  async onSubmit(payload: IFormSubmitPayload) {
    await this.dialogConfig.onSubmitAction(payload);

    if (this.dialogConfig.closeDialogOnSubmitted === true) {
      this.dialogRef.close();
    }
  }
}
