import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@rcg/auth';
import { RcgContact } from '@rcg/core/models';
import { GraphqlClientService } from '@rcg/graphql';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { contactsQuery } from './contacts.gql';

@Component({
  selector: 'rcg-contacts-suggestor',
  templateUrl: './contacts-suggestor.component.html',
  styleUrls: ['./contacts-suggestor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsSuggestorComponent implements OnChanges, AfterViewInit {
  @Input() search?: string;

  ngOnChanges(changes: SimpleChanges) {
    if ('search' in changes) {
      this.contactSearch$.next(changes.search.currentValue);
    }
  }

  @Output() contactSelected = new EventEmitter<RcgContact | null>();

  @ViewChild('contactsList') contactsList?: ElementRef<HTMLDivElement>;

  constructor(
    private readonly viewRef: ViewContainerRef,
    private readonly gql: GraphqlClientService,
    private readonly authService: AuthService,
  ) {}

  contactSearch$ = new BehaviorSubject<string>('');

  private readonly tenant = this.authService.tenant;

  contacts = toSignal(
    this.contactSearch$.pipe(
      switchMap((search) =>
        this.gql
          .query<{ data?: RcgContact[] }>({
            query: contactsQuery,
            variables: { search: search, limit: 3, orgId: this.tenant()?.organization.id, tenantId: this.tenant()?.id },
          })
          .pipe(map((d) => d.data)),
      ),
    ),
  );

  selectedIndex = signal(0);

  ngAfterViewInit() {
    if (this.contactsList) {
      this.contactsList.nativeElement.focus();
    }
  }

  public keyboardAction(action: string) {
    if (action === 'up') {
      this.selectedIndex.set((this.selectedIndex() - 1 + (this.contacts()?.length ?? 0)) % (this.contacts()?.length ?? 0));
    } else if (action === 'down') {
      this.selectedIndex.set((this.selectedIndex() + 1) % (this.contacts()?.length ?? 0));
    } else if (action === 'enter') {
      this.selectContact(this.selectedIndex());
    }
    this.selectedIndex.set(Math.max(0, Math.min(this.selectedIndex(), (this.contacts()?.length ?? 0) - 1)));
  }

  public selectContact(index: number) {
    this.contactSelected.emit(this.contacts()?.[index] ?? null);
  }

  contactSelectedClick(contact: RcgContact) {
    this.contactSelected.emit(contact);
  }
}
