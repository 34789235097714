import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ATTACHMENT_BLOB_URL_LOADING, Attachment, AttachmentBlobUrl, EmlAttachment } from '@rcg/core/models';
import { attachmentFilename, isEmlAttachment } from '@rcg/core/utils/attachment';

@Component({
  selector: 'rcg-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailComponent<T extends Attachment | EmlAttachment> implements OnInit, OnChanges {
  readonly attachmentFilename = attachmentFilename;
  readonly isEmlAttachment = isEmlAttachment;

  @Output() thumbnailClick = new EventEmitter<T>();

  @Input() attachment!: T;
  @Input() blobUrl?: AttachmentBlobUrl | null;

  @HostBinding('class.selected')
  @HostBinding('class.mat-mdc-elevation-specific')
  @HostBinding('class.mat-elevation-z4')
  @Input()
  selected?: boolean;

  @HostBinding('class.inline')
  inline?: boolean;

  blobUrlState: 'loading' | 'loaded' | 'missing' = 'loading';

  get strBlobUrl(): string {
    return this.blobUrl as string;
  }

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.updateBlobUrlType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('blobUrl' in changes) this.updateBlobUrlType();
    if ('attachment' in changes) this.inline = isEmlAttachment(this.attachment) ? false : this.attachment.inline;
  }

  private updateBlobUrlType() {
    if (this.blobUrl === ATTACHMENT_BLOB_URL_LOADING) this.blobUrlState = 'loading';
    else if (this.blobUrl) this.blobUrlState = 'loaded';
    else this.blobUrlState = 'missing';

    setTimeout(() => this.changeRef.markForCheck());
  }

  onThumbnailClicked(attachment: T) {
    this.thumbnailClick.emit(attachment);
  }
}
