import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldCollection } from '../models';

export const fieldObjectNestedFieldType = 'nestedFieldBase';
export const fieldObjectNestedFieldProperty = 'nestedFields';

export const formlyFieldsToObject = (fields: FormlyFieldConfig[]): FormlyFieldCollection => {
  const fieldsCollection: FormlyFieldCollection = {};

  for (const f of fields) {
    if (!f.key) continue;
    const strKey = f.key.toString();

    if (strKey.indexOf('.') > 0) {
      const baseKey = strKey.substring(-1, f.key!.toString().indexOf('.'));

      if (!fieldsCollection[baseKey]) {
        fieldsCollection[baseKey] = {
          type: fieldObjectNestedFieldType,
          key: baseKey,
          [fieldObjectNestedFieldProperty]: [],
        } as unknown as FormlyFieldConfig;
      }

      const baseField = fieldsCollection[baseKey];

      if (baseField.type !== fieldObjectNestedFieldType) {
        throw new Error(`Invalid nested field base type: ${baseField.type}`);
      }

      (baseField as { [fieldObjectNestedFieldProperty]: FormlyFieldConfig[] })[fieldObjectNestedFieldProperty]!.push(f);
    } else {
      fieldsCollection[f.key as string] = f;
    }
  }

  return fieldsCollection;
};
