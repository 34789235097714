//FIXME: Remove use of any
/* eslint-disable @typescript-eslint/no-explicit-any */

export function getFieldValueFromPath(model: any, path: string | null | undefined): any {
  const valuePath: null | string = path?.trim() ?? null;

  if (!valuePath) {
    return null;
  }

  if (valuePath.indexOf('.') === -1) {
    return model?.[valuePath];
  }

  const splitedNames = valuePath.split('.');
  if (splitedNames.length === 1) {
    return model?.[splitedNames[0]];
  }

  let initialValue: any = model?.[splitedNames[0]];
  // if (splitedNames[0].indexOf("[")>0){
  //   initialValue = model?.[splitedNames[0].substring(-1,splitedNames[0].indexOf("["))];
  //   const valIndex=splitedNames[0].substring(splitedNames[0].indexOf("[")+1,splitedNames[0].indexOf("]"));
  //   initialValue=initialValue[valIndex];
  // }
  for (let i = 1; i < splitedNames.length; i++) {
    const name = splitedNames[i];
    if (!initialValue) {
      break;
    }
    if (!initialValue?.[name]) {
      break;
    }
    // if (name.indexOf("[")>0){
    //   initialValue = initialValue[name.substring(-1,name.indexOf("["))];
    //   const valIndex=name.substring(name.indexOf("["),name.indexOf("]"));
    // }
    // else{
    //   initialValue = initialValue[name];
    // }
    initialValue = initialValue[name];
  }
  return initialValue;
}

export function getNestedFieldData(fieldData: any, path: string | null) {
  if (!path) return fieldData;

  let data = fieldData;

  for (const pathPart of path.split('.')) {
    data = data?.[pathPart];
  }

  return data;
}

export function extractFieldData(fieldData: any, definition: any) {
  let data = fieldData;

  if (typeof definition === 'string') {
    data = getNestedFieldData(fieldData, definition);
  } else if (definition.field) {
    data = getNestedFieldData(fieldData, definition.field);
  } else if (definition.literal) {
    data = definition.literal;
  }

  for (const p of definition?.pipe ?? []) {
    data = p(data);
  }

  return data;
}
