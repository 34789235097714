import { Injectable, inject } from '@angular/core';
import { GraphqlClientService } from '@rcg/graphql';
import { Observable, map } from 'rxjs';
import { formFieldActionsQuery } from '../../gql/actions';
import { FormFieldActionsRequestInput, IAction } from '../../models/actions';
import { filterAndSortActions } from '../actions-utils';

@Injectable({
  providedIn: 'root',
})
export class FormFieldsActions {
  private graphQlClient = inject(GraphqlClientService);

  getFormFieldActions(input: FormFieldActionsRequestInput): Observable<IAction[]> {
    return this.graphQlClient
      .query<{ actions?: { data?: IAction[] } }>({
        query: formFieldActionsQuery,
        variables: {
          tenantId: input.tenantId!,
          organizationId: input.organizationId!,
          fieldId: input.fieldId!,
          recordId: input.recordId,
          formId: input.formId,
          moduleId: input.moduleId,
          tableName: input.tableName,
          platform: 'web',
          contextType: input.contextType,
          caller: input.caller,
        },
      })
      .pipe(
        map((res) => {
          if (res?.actions?.data && res?.actions?.data?.length > 0) {
            return filterAndSortActions(res.actions.data);
          }
          return [];
        }),
      );
  }
}
