import { createReducer, on } from '@ngrx/store';
import { IPhoneCall } from '@rcg/core/models';
import * as phoneCallsActions from './phone-calls.actions';

export interface PhoneCallsState {
  phoneCalls: IPhoneCall[];
  initialized: boolean;
  error: string | null;
  loading: boolean;
}

const initialState: PhoneCallsState = {
  phoneCalls: [],
  initialized: false,
  error: null,
  loading: false,
};

export const phoneCallsReducer = createReducer(
  initialState,
  on(
    phoneCallsActions.registerToPhoneCalls,
    (state): PhoneCallsState => ({
      ...state,
      error: null,
      loading: true,
      initialized: false,
    }),
  ),
  on(
    phoneCallsActions.registerToPhoneCallsSuccess,
    (state, payload): PhoneCallsState => ({
      ...state,
      loading: false,
      phoneCalls: payload.phoneCals,
      initialized: true,
    }),
  ),
  on(
    phoneCallsActions.registerToPhoneCallsFailed,
    (state, payload): PhoneCallsState => ({
      ...state,
      error: payload.error,
      loading: false,
      phoneCalls: [],
      initialized: true,
    }),
  ),
  on(phoneCallsActions.unRegisterFromPhoneCalls, (): PhoneCallsState => initialState),
  on(
    phoneCallsActions.setTestPhoneCall,
    (state, payload): PhoneCallsState => ({
      ...state,
      phoneCalls: [payload.phoneCal],
    }),
  ),
  on(
    phoneCallsActions.clearTestPhoneCall,
    (state): PhoneCallsState => ({
      ...state,
      phoneCalls: [],
    }),
  ),
);
