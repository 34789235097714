import { createReducer, on } from '@ngrx/store';
import { IPhoneExtension } from '@rcg/core/models';
import * as phoneExtensionsActions from './phone-extensions.actions';

export interface PhoneExtensionsState {
  extensions: IPhoneExtension[];
  initialized: boolean;
  error: string | null;
  loading: boolean;
}

const initialState: PhoneExtensionsState = {
  extensions: [],
  initialized: false,
  error: null,
  loading: false,
};

export const phoneExtensionsReducer = createReducer(
  initialState,
  on(
    phoneExtensionsActions.registerPhoneExtensions,
    (state): PhoneExtensionsState => ({
      ...state,
      error: null,
      loading: true,
      initialized: false,
    }),
  ),
  on(
    phoneExtensionsActions.registerPhoneExtensionsSuccess,
    (state, payload): PhoneExtensionsState => ({
      ...state,
      loading: false,
      extensions: payload.extensions,
      initialized: true,
    }),
  ),
  on(
    phoneExtensionsActions.registerPhoneExtensionsFailed,
    (state, payload): PhoneExtensionsState => ({
      ...state,
      error: payload.error,
      loading: false,
      extensions: [],
      initialized: true,
    }),
  ),
  on(phoneExtensionsActions.unRegisterPhoneExtensions, (): PhoneExtensionsState => initialState),
);
