<rcg-dialog
  #dialog
  *ngIf="dialogConfig.dialogTitle"
  [dialogRef]="dialogRef"
  [icon]="dialogConfig.dialogIcon ?? 'description'"
  [title]="dialogConfig.dialogTitle!"
>
  <rcg-formly-custom-form
    class="form"
    [model]="dialogConfig.model"
    [fields]="dialogConfig.formFields"
    [formOptions]="formOptions"
    [title]="dialogConfig.formTitle"
    [allowSubmitOnPristine]="dialogConfig.allowSubmitOnPristine"
    (submitForm)="onSubmit($event)"
  ></rcg-formly-custom-form>
</rcg-dialog>
