<div class="rcg-assist-light-theme" style="display: inline-block;">
  <div style="display: inline-grid;">
    <input #input style="grid-row: 1 / 1; grid-column: 1 / 1;" type="text" placeholder="Knowledgebase" [formControl]="control" [matAutocomplete]="auto" [matAutocompleteConnectedTo]="origin" (ngModelChange)="dataChange($event)">
    <mat-progress-bar *ngIf="loading" style="grid-row: 1 / 1; grid-column: 1 / 1; align-self: end;" mode="indeterminate"></mat-progress-bar>
  </div>
  
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-optgroup *ngFor="let option of filteredOptions | async" [label]="option.question ?? ('ID: ' + option.id)">
      <mat-option *ngFor="let entry of option.entries" [value]="entry" style="line-height: 20px; margin-left: -16px;">
        <div style="display: flex; align-items: center;">
          <pre style="margin-right: 4px; color: gray; transform: scale(.8);">{{entry.lang}}</pre>
          <span>{{entry.plain}}</span>
        </div>
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
  
  <!-- <span style="outline: 1px solid orange; margin-left: 4px; padding: 0 4px;">ID: {{identifier | json}}</span> -->
  
  <div matAutocompleteOrigin #origin="matAutocompleteOrigin" style="position: absolute;" [ngStyle]="originPos"></div>
</div>