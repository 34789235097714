import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { GraphqlClientService } from '@rcg/graphql';
import { Observable, firstValueFrom, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { SuggestorComponent } from '../html-editor/suggestions';
import { knowledgebaseQuery, knowledgebaseSearchQuery } from './knowledgebase.gql';

interface TranslatedText {
  [lang: string]: string;
}

interface KnowledgebaseEntryGroup {
  id: number;
  question?: string;
  entries: KnowledgebaseEntry[];
}

interface KnowledgebaseEntry {
  id: number;
  lang: string;
  question: string | undefined;
  plain: string | undefined;
  html: string | undefined;
}

interface KnowledgebaseQueryResult {
  servicedesk_search_knowledge_base: {
    id: number;
    question?: string;
    answer?: {
      html?: TranslatedText;
      plain?: TranslatedText;
    };
  }[];
}

@Component({
  selector: 'rcg-knowledgebase-suggestor',
  templateUrl: './knowledgebase-suggestor.component.html',
  styleUrls: ['./knowledgebase-suggestor.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class KnowledgebaseSuggestorComponent implements SuggestorComponent, OnInit, AfterViewInit {
  @Input() suggestion?: string;
  @Input() identifier?: string;

  @Output() identifierChange = new EventEmitter<string | undefined>();

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  originPos = { top: '', left: '', width: '' };

  control = new FormControl<string | (KnowledgebaseEntry & { _initial: boolean })>('');
  filteredOptions?: Observable<KnowledgebaseEntryGroup[]>;

  loading = true;

  constructor(private readonly viewRef: ViewContainerRef, private readonly gql: GraphqlClientService) {}

  @HostListener('keydown', ['$event'])
  @HostListener('keypress', ['$event'])
  @HostListener('keyup', ['$event'])
  onKeyEvent(event: KeyboardEvent): void {
    //? Keep event within this component
    event.stopPropagation();
  }

  ngOnInit() {
    this.filteredOptions = this.control.valueChanges.pipe(
      debounceTime(500),
      switchMap((value) => {
        if (typeof value !== 'string') return of([]);
        if (value.length < 1) return of([]);

        return this.gql
          .query<KnowledgebaseQueryResult>({
            query: knowledgebaseSearchQuery,
            variables: {
              search: value,
              tenant_id: 1,
            },
          })
          .pipe(
            map((result) =>
              result.servicedesk_search_knowledge_base
                .map<KnowledgebaseEntryGroup>((r) => ({
                  id: r.id,
                  question: r.question,
                  entries: Object.keys({ ...r.answer?.html, ...r.answer?.plain }).map<KnowledgebaseEntry>((lang) => ({
                    id: r.id,
                    lang,
                    question: r.question,
                    plain: r.answer?.plain?.[lang],
                    html: r.answer?.html?.[lang],
                  })),
                }))
                .filter((grp) => grp.entries.length > 0),
            ),
            tap(() => (this.loading = false)),
          );
      }),
    );

    this.initData();

    this.updateOriginPos();
  }

  ngAfterViewInit() {
    if (!this.identifier) {
      this.input?.nativeElement?.focus();

      if (this.suggestion) {
        this.control.setValue(this.suggestion);
      }
    }
  }

  async initData() {
    if (this.identifier) {
      const split = this.identifier.split('/');

      if (split.length !== 2 || isNaN(parseInt(split[0]))) {
        console.error('[KnowledgebaseSuggestorComponent]', 'invalid identifier', this.identifier);
        return;
      }

      const [id, lang] = split;

      const result = await firstValueFrom(
        this.gql.query<{
          servicedesk_knowledge_base_by_pk: { question: string | undefined; plain: string | undefined; html: string | undefined };
        }>({
          query: knowledgebaseQuery,
          variables: {
            id,
            plain_path: `plain.${lang}`,
            html_path: `html.${lang}`,
          },
        }),
      );

      const { question, plain, html } = result.servicedesk_knowledge_base_by_pk;
      this.control.setValue({ id: +id, lang, question, plain, html, _initial: true });

      this.loading = false;
    }

    if (!this.suggestion) {
      this.loading = false;
    }
  }

  updateOriginPos() {
    const frameBoundingRect = document.getElementsByClassName('k-iframe')[0]?.getBoundingClientRect();
    if (!frameBoundingRect) return;

    const elementBoundingRect = (this.viewRef.element.nativeElement as HTMLElement).getBoundingClientRect();

    this.originPos = {
      top: frameBoundingRect.top + elementBoundingRect.bottom + 'px',
      left: frameBoundingRect.left + elementBoundingRect.left - 8 + 'px',
      width: elementBoundingRect.width + 'px',
    };
  }

  displayFn(value: string | KnowledgebaseEntry): string {
    if (typeof value === 'string') return value;
    if (value?.question) return `#${value.id}: ${value.plain}`;
    return '';
  }

  dataChange(value: string | (KnowledgebaseEntry & { _initial: boolean })) {
    if (typeof value === 'string') return;
    if (value._initial) return;

    this.identifier = `${value.id}/${value.lang}`;
    this.identifierChange.emit(this.identifier);
  }
}
