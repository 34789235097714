import { gql } from '@apollo/client/core';

export const getcontactQuery = gql`
  query getContactQuery($id: bigint!) {
    data: global_contacts_by_pk(id: $id) {
      id
      name: full_name
      created_at
      data
      email
      employer_id
      employer {
        id
        long_name
        short_name
      }
      organization_id
      organization {
        id
        long_name
        short_name
      }
      first_name
      last_name
    }
  }
`;
export const searchContactsQuery = gql`
  query searchContactsQueryQuery($number: String!, $org_id: bigint!) {
    data: global_search_contacts(args: { search: $number, org_id: $org_id }) {
      id
      name: full_name
    }
  }
`;

export const updateContactMutation = gql`
  mutation updateContactMutation(
    $id: bigint!
    $first_name: String
    $last_name: String
    $email: citext
    $telephone: jsonb
    $address: String
  ) {
    update_global_contacts_by_pk(
      pk_columns: { id: { _eq: $id } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        telephone: $telephone
      }
      _append: { data: { address: $address } }
    ) {
      affected_rows
    }
  }
`;
