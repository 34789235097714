import { gql } from '@apollo/client/core';

export const organizationPhoneCallsSubscription = gql`
  subscription organizationPhoneCallsSubscription(
    $offset: Int
    $limit: Int
    $organization_id: bigint
  ) {
    data: telephony_phone_call(
      where: {
        _and: [
          { organization_id: { _eq: $organization_id } }
          { is_deleted: { _eq: false } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
    ) {
      id
      organization_id
      server_id
      call_id
      call_connection_id
      history_id_of_the_call
      contact_id
      contact_name
      agent_name
      caller
      reciever
      is_inbound
      is_outbound
      status
      start_ringing
      start_talking
      start
      end
      no_of_missed_calls
      queue_ref {
        name
        tags
      }
    }
  }
`;

export const searchOrganizationPhoneCallsSubscription = gql`
  subscription searchOrganizationPhoneCallsSubscription(
    $offset: Int
    $limit: Int
    $organization_id: bigint
    $search: String
  ) {
    data: telephony_phone_call(
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
      where: {
        _and: {
          organization_id: { _eq: $organization_id }
          is_deleted: { _eq: false }
          _or: [
            { agent_name: { _ilike: $search } }
            { caller: { _ilike: $search } }
            { contact_name: { _ilike: $search } }
            { reciever: { _ilike: $search } }
          ]
        }
      }
    ) {
      id
      organization_id
      server_id
      call_id
      call_connection_id
      contact_id
      history_id_of_the_call
      contact_name
      agent_name
      caller
      reciever
      is_inbound
      is_outbound
      status
      start_ringing
      start_talking
      start
      end
      no_of_missed_calls
      queue_ref {
        name
        tags
      }
    }
  }
`;
