import { gql } from '@apollo/client/core';

export const organizationPhoneExtensionsSubscription = gql`
  subscription organizationPhoneExtensionSubscription(
    $organization_id: bigint
    $limit: Int
  ) {
    data: telephony_extensions(
      limit: $limit
      where: { organization_id: { _eq: $organization_id } }
      order_by: { name: asc }
    ) {
      id
      user_id
      organization_id
      server_id
      name
      email
      status
      status_type
      register
      queues
      queue_status
      talking
    }
  }
`;
