import { createSelector } from '@ngrx/store';
import { selectPhoneFeature } from '../index';

export const selectPhoneExtensionsState = createSelector(
  selectPhoneFeature,
  (state) => {
    return state.extensions;
  }
);

export const selectPhoneExtensionsInitialized = createSelector(
  selectPhoneExtensionsState,
  (state) => {
    return state.initialized;
  }
);

export const selectPhoneExtensions = createSelector(
  selectPhoneExtensionsState,
  (state) => {
    return state.extensions;
  }
);

export const selectActivePhoneExtension = createSelector(
  selectPhoneExtensions,
  (state) => {
    return state.length > 0 ? state[0] : null;
  }
);

export const selectPhoneExtensionsLoading = createSelector(
  selectPhoneExtensionsState,
  (state) => {
    return state.loading;
  }
);

export const selectPhoneExtensionsError = createSelector(
  selectPhoneExtensionsState,
  (state) => {
    return state.error;
  }
);
