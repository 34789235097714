import { gql } from '@apollo/client/core';

export const formActionsQuery = gql`
  query formActions(
    $tenantId: bigint!
    $organizationId: bigint!
    $moduleId: Int!
    $formId: Int!
    $recordId: bigint!
    $tableName: String
    $platform: String
    $contextType: String
    $caller: String
  ) {
    actions: formActionsQuery(
      input: {
        formId: $formId
        organizationId: $organizationId
        moduleId: $moduleId
        recordId: $recordId
        tenantId: $tenantId
        tableName: $tableName
        platform: $platform
        contextType: $contextType
        caller: $caller
      }
    ) {
      data
    }
  }
`;

export const formFieldActionsQuery = gql`
  query formFieldActions(
    $tenantId: bigint!
    $organizationId: bigint!
    $formId: Int!
    $fieldId: String!
    $moduleId: Int
    $recordId: bigint
    $tableName: String
    $platform: String
    $contextType: String
    $caller: String
  ) {
    actions: formFieldActionsQuery(
      input: {
        formId: $formId
        organizationId: $organizationId
        fieldId: $fieldId
        moduleId: $moduleId
        recordId: $recordId
        tenantId: $tenantId
        tableName: $tableName
        platform: $platform
        contextType: $contextType
        caller: $caller
      }
    ) {
      data
    }
  }
`;
