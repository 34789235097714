/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable, inject } from '@angular/core';
import { RcgTenant, RcgUser } from '@rcg/auth';
import { IPhoneCall } from '@rcg/core/models';
import { formlyFieldsToObject } from '@rcg/core/utils/form-utils';
import { FormDialogService, FormGlobalVarsService, FormValueConverterService } from '@rcg/forms/services';
import { SupportedForm, getFormFields } from '@rcg/forms/utils/supported-forms-utils';
import { tr } from '@rcg/intl';
import { PhoneService } from '@rcg/phone/services/phone.service';
import { MessageService } from '@rcg/standalone/services';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { ActionsUtils } from '../actions-utils';
import { HelpdeskActions } from './helpdesk-actions';

@Injectable({
  providedIn: 'root',
})
export class PhoneActions {
  private messageService = inject(MessageService);
  private formDialogService = inject(FormDialogService);
  private formValueConverter = inject(FormValueConverterService);
  private formGlobalVarsService = inject(FormGlobalVarsService);
  private phoneService = inject(PhoneService);
  private helpdeskActions = inject(HelpdeskActions);
  private actionsUtils = inject(ActionsUtils);

  async linkPhoneCall(call: IPhoneCall, user: RcgUser, tenant: RcgTenant) {
    const translation = await firstValueFrom(
      combineLatest([
        tr('new_event'),
        tr('new_event_or_link_existing'),
        tr('link'),
        tr('new'),
        tr('call_link_with_event'),
        tr('error_link_call_to_event'),
      ]).pipe(
        map(([newEvent, newEventOrExisting, link, addNew, call_link_with_event, linkError]) => ({
          newEvent,
          newEventOrExisting,
          link,
          addNew,
          call_link_with_event,
          linkError,
        })),
      ),
    );

    const dialogResult = await this.messageService.confirmDialogAsync({
      icon: 'new_label',
      title: `${translation.newEvent}?`,
      message: `${translation.newEventOrExisting}?`,
      cancelText: translation.link,
      confirmText: translation.addNew,
    });

    if (dialogResult === null) return;

    if (dialogResult) {
      try {
        const helpdeskItem = await this.helpdeskActions.addNewHelpdeskItem(user, tenant, call);
        if (!helpdeskItem?.id) return;

        const linkToRecord = { table: 'HD', id: helpdeskItem.id };
        this.phoneService.linkPhoneCall(call, [linkToRecord]);

        const { table, id } = linkToRecord;
        this.messageService.showInfoSnackbar(`${translation.call_link_with_event} ${table}-${id}`);
      } catch (error) {
        this.messageService.showErrorSnackbar(`${translation.linkError}`, error);
      }

      return;
    }

    const orgId = this.actionsUtils.getAuthInfo().tenant.organization.id;

    const fields = (await getFormFields(SupportedForm.link_phone_call_to_existing_ticket, this.formGlobalVarsService)).fields;

    this.formDialogService.openCustomForm({
      formFields: fields,
      formOptions: {},
      prefilledGqlVariables: {
        id: {
          orgId,
        },
      },
      model: {},
      dialogTitle: await firstValueFrom(tr('choose_event')),
      width: '400px',
      height: '200px',
      closeDialogOnSubmitted: true,
      onSubmitAction: async ({ model }) => {
        try {
          const linkToRecord = this.formValueConverter.getFormValuesForInsert(
            {
              formState: {
                noneGenericInsertInputDataVariables: true,
              },
            },
            model!,
            formlyFieldsToObject(fields),
            null,
          ) as { table: string; id: number };

          this.phoneService.linkPhoneCall(call, [linkToRecord]);

          const { table, id } = linkToRecord;
          this.messageService.showInfoSnackbar(`${translation.call_link_with_event}  ${table}-${id}`);
        } catch (error) {
          this.messageService.showErrorSnackbar(`${translation.linkError}`, error);
        }
      },
    });
  }

  async makePhoneCall(phoneCall: IPhoneCall) {
    if (!phoneCall?.id || !(phoneCall.status === 'Finished' || phoneCall.status === 'Missed')) {
      return;
    }

    const callNumber = this.phoneService.getCallNumberForMakeCall(phoneCall);
    if (!callNumber) {
      return;
    }

    try {
      await this.phoneService.makePhoneCall(callNumber);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const trError = await firstValueFrom(tr('error_calling_number'));
      this.messageService.showErrorSnackbar(`${trError}: ${callNumber}: ${error?.message ?? error.toString()}`);
    }
  }
}
