<rcg-dialog
  #dialog
  [dialogRef]="dialogRef"
  icon="attach_file"
  title="{{ dialogConfig.dialogTitle ?? ('attachments' | intl) }} - {{
    selectedAttachment ? attachmentFilename(selectedAttachment) ?? ('attachment_has_no_name' | intl) : ('no_selected_attachment' | intl)
  }}"
>
  <ng-container ngProjectAs="toolbar-icons">
    <mat-icon
      *ngIf="canDeleteSelectedAttachment()"
      [matTooltip]="'delete_attachment' | intl"
      matTooltipPosition="below"
      (click)="deleteSelectedAttachment()"
      >delete</mat-icon
    >
    <mat-icon [matTooltip]="'download_attachment' | intl" matTooltipPosition="below" (click)="downloadSelectedAttachmentToDisk()"
      >download</mat-icon
    >
  </ng-container>

  <div class="dialog-content">
    <rcg-thumbnails-container
      class="thumbnails"
      *ngIf="attachments.length > 0 && thumbnailBlobUrls"
      [attachments]="attachments"
      [blobUrls]="thumbnailBlobUrls"
      (thumbnailClick)="onThumbnailClick($event)"
      [selectedThumbnailIndex]="selectedAttachment ? attachments.indexOf(selectedAttachment) : undefined"
    ></rcg-thumbnails-container>

    <ng-container *ngIf="errorMessage; else noErrorTemplate">
      <div class="error">
        <span>{{ errorMessage }}</span>
      </div>
    </ng-container>
  </div>
</rcg-dialog>

<ng-template #noErrorTemplate>
  <ng-container *ngIf="loading; then loadingTemplate; else notLoadingTemplate"></ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loading">
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #notLoadingTemplate>
  <ng-container *ngIf="attachments.length && selectedAttachment; then hasAttachmentsTemplate; else noAttachmentsTemplate"></ng-container>
</ng-template>

<ng-template #hasAttachmentsTemplate>
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="isImage">
      <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isVideo">
      <ng-container *ngTemplateOutlet="videoTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isHtml">
      <ng-container *ngTemplateOutlet="htmlTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isJson">
      <ng-container *ngTemplateOutlet="codeTemplate; context: { lang: 'json' }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isXml">
      <ng-container *ngTemplateOutlet="codeTemplate; context: { lang: 'xml' }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isText && !isHtml && !isJson && !isXml">
      <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isDoc">
      <ng-container *ngTemplateOutlet="docTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isPdf">
      <ng-container *ngTemplateOutlet="pdfTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="isEml || isMsg">
      <ng-container *ngTemplateOutlet="emlTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="noPreviewTemplate"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noAttachmentsTemplate>
  <div class="no-attachments">
    <span>{{ 'no_attachments' | intl }}</span>
  </div>
</ng-template>

<ng-template #imageTemplate>
  <img class="image-preview" [src]="selectedBlobUri! | trust : 'url'" />
</ng-template>

<ng-template #videoTemplate>
  <div class="video-preview">
    <video [src]="selectedBlobUri! | trust : 'url'" controls></video>
  </div>
</ng-template>

<ng-template #htmlTemplate>
  <iframe
    class="html-preview"
    frameborder="0"
    sandbox
    src='data:text/html;charset=utf-8,<html><head><meta name="darkreader-lock"></head></html>'
    [srcdoc]="htmlText | async | trust : 'html'"
  ></iframe>
</ng-template>

<ng-template #textTemplate>
  <div class="text-preview" [innerHTML]="sanitizedText | async | trust : 'html'"></div>
</ng-template>

<ng-template #docTemplate>
  <iframe class="doc-preview" [src]="docEmbedUrl | trust : 'resourceUrl'" frameborder="0"></iframe>
</ng-template>

<ng-template #pdfTemplate>
  <object class="pdf-preview" [data]="pdfEmbedUrl | trust : 'resourceUrl'" type="application/pdf" width="750px" height="750px">
    <embed [src]="pdfEmbedUrl | trust : 'resourceUrl'" type="application/pdf" />
  </object>
</ng-template>

<ng-template #codeTemplate let-lang="lang">
  <div class="code-preview">
    <pre class="line-numbers"><code class="hljs" [innerHTML]="textLineNumbers | async | trust : 'html'"></code></pre>

    <pre class="code" (ngAfterViewInit)="highlightCode(sanitizedText, code)"><!--
   --><code #code [class]="'language-' + lang" [innerHTML]="sanitizedText | async | trust : 'html'"></code><!--
 --></pre>
  </div>
</ng-template>

<ng-template #emlTemplate>
  @if (emlEmail | async; as email) {
  <div class="eml-preview">
    <div class="eml-preview-headers">
      <button mat-mini-fab class="headers-toggle-button" (click)="toggleEmlHeaders()">
        <mat-icon>{{ emlHeadersExpanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
      </button>

      @if (emlHeadersExpanded) {
      <table>
        @for (header of shownEmlHeaders; track header[0]) {
        <tr class="eml-header" *ngIf="header[2](email) as value">
          <th class="eml-header-name">{{ header[0] | intl }}:</th>

          <ng-container [ngSwitch]="header[1]">
            <td class="eml-header-value" *ngSwitchCase="'html'" [innerHTML]="value"></td>
            <td class="eml-header-value" *ngSwitchCase="'plain'">{{ value }}</td>
            <td class="eml-header-value" *ngSwitchCase="'date'">{{ value | date : 'long' }}</td>
          </ng-container>
        </tr>
        }
      </table>
      } @else {
      <span class="collapsed-headers-text">{{ email.headers.subject }}</span>
      }
    </div>

    @if (emlHtmlProgress$ | async; as progress) {
    <mat-progress-bar *ngIf="progress < 1" mode="buffer" [bufferValue]="progress * 100"></mat-progress-bar>
    }

    <iframe
      *ngIf="emlHtml$ | async as html"
      class="eml-preview-content"
      sandbox="allow-same-origin"
      frameborder="0"
      src="data:text/html;charset=utf-8"
      [srcdoc]="html | trust : 'html'"
    ></iframe>
  </div>
  } @else {
  <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
  }
</ng-template>

<ng-template #noPreviewTemplate>
  <div class="no-preview">
    <div>{{ 'preview_not_available' | intl }}</div>
    <div *ngIf="attachmentContentType(selectedAttachment) as contentType">{{ contentType }}</div>
    <div *ngIf="selectedBlob?.type && selectedBlob?.type !== attachmentContentType(selectedAttachment)">{{ selectedBlob?.type }}</div>
  </div>
</ng-template>
