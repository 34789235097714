import { gql } from '@apollo/client/core';

const contactDataFragment = gql`
  fragment contactData on global_contacts {
    id
    user_id
    external_id
    created_at
    updated_at
    organization_id
    employer_id
    first_name
    last_name
    email
    telephone
    data
  }
`;

export const contactsQuery = gql`
  ${contactDataFragment}
  query contactsQuery($limit: Int!, $search: String!, $orgId: bigint!, $tenantId: bigint = "") {
    data: global_search_contacts(args: { search: $search, org_id: $orgId }, limit: $limit, where: { tenant_id: { _eq: $tenantId } }) {
      ...contactData
    }
  }
`;
