import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@rcg/auth';
import { tr } from '@rcg/intl';
import { MessageService } from '@rcg/standalone/services';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { HttpRequestActionConfig, IAction } from '../../models/actions';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestActions {
  private authService = inject(AuthService);
  private http = inject(HttpClient);
  private messageService = inject(MessageService);

  async executeHttpRequest(action: IAction) {
    try {
      const { url, authenticate, httpMethod, requestData, headers, responseType, afterResponseAction } =
        action.config as HttpRequestActionConfig;

      const translation = await firstValueFrom(
        combineLatest([
          tr('no_url_parameter'),
          tr('unsupported_http_method_type'),
          tr('unsupported_http_response_type'),
          tr('no_auth_token'),
          tr('http_request_failed'),
          tr('action_success'),
          tr('action_error'),
        ]).pipe(
          map(
            ([noUrlParameter, unsupportedHttpMethodType, unsupportedHttpResponseType, noAuthToken, httpRequestFailed, actionSuccess]) => ({
              noUrlParameter,
              unsupportedHttpMethodType,
              unsupportedHttpResponseType,
              noAuthToken,
              httpRequestFailed,
              actionSuccess,
            }),
          ),
        ),
      );

      // console.log('Http request action', authenticate, httpMethod, url, responseType, afterResponseAction, requestData, headers);
      if (!url) throw new Error(translation.noUrlParameter);
      if (!httpMethod || !['get', 'post', 'put', 'delete'].includes(httpMethod))
        throw new Error(`${translation.unsupportedHttpMethodType} ${httpMethod}. Supported type: get, post, put, delete`);
      if (!responseType || !['blob', 'json', 'text', 'arraybuffer'].includes(responseType))
        throw new Error(`${translation.unsupportedHttpResponseType} ${responseType}. Supported types: blob, json, text, arraybuffer`);

      let requestHeaders: { [header: string]: string } = {};
      if (headers && Object.keys(headers).length > 0) {
        requestHeaders = headers;
      }
      //no_auth_token

      if (authenticate) {
        const token = await this.authService.getRawAuthToken();
        if (!token) throw new Error(translation.noAuthToken);
        requestHeaders = {
          ...{
            Authorization: `Bearer ${token}`,
          },
        };
      }

      const response = await this.makeRequest(url, httpMethod, requestHeaders, responseType, requestData);

      if (!response) {
        throw new Error(`${translation.httpRequestFailed}: No http response`);
      }

      if (response.status !== 200) {
        throw new Error(`${translation.httpRequestFailed}. Status: ${response.status}`);
      }

      const afterResponse = afterResponseAction?.trim();

      if (!afterResponse) {
        this.messageService.showInfoSnackbar(`${translation.actionSuccess} - ${action.name}`);
        return;
      }

      // after response actions
      switch (afterResponse) {
        case 'saveFile':
          await this.saveFile(response.body as Blob | undefined, response.headers);
          break;
        default:
          throw new Error(`Unsupported after response action: ${afterResponse}`);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = await firstValueFrom(tr('action_error'));
      this.messageService.showErrorSnackbar(`${errorMessage} ${action.name}`, `${error?.message ?? error}`);
    }
  }

  private async makeRequest(
    url: string,
    httpMethod: string,
    headers: { [header: string]: string },
    responseType: string,
    requestData: Record<string, unknown> | undefined,
  ): Promise<HttpResponse<unknown> | undefined> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseTypeResult: any =
      responseType === 'blob'
        ? 'blob'
        : responseType === 'json'
        ? 'json'
        : responseType === 'text'
        ? 'text'
        : responseType === 'arraybuffer'
        ? 'arraybuffer'
        : null;

    const translation = await firstValueFrom(
      combineLatest([tr('wrong_http_response_type'), tr('unsupported_http_method_type')]).pipe(
        map(([wrongHttpResponseType, unsupportedHttpMethodType]) => ({
          wrongHttpResponseType,
          unsupportedHttpMethodType,
        })),
      ),
    );

    if (!responseType) {
      throw new Error(`${translation.wrongHttpResponseType}: ${responseType}`);
    }

    switch (httpMethod) {
      case 'get':
        return await firstValueFrom(
          this.http.get(url, {
            observe: 'response',
            headers: headers,
            responseType: responseTypeResult,
          }),
        );
      case 'post':
      case 'put':
        return await firstValueFrom(
          this.http.post(url, requestData ?? {}, {
            observe: 'response',
            headers: headers,
            responseType: responseTypeResult,
          }),
        );
      case 'delete':
        return await firstValueFrom(
          this.http.delete(url, {
            observe: 'response',
            headers: headers,
            responseType: responseTypeResult,
          }),
        );
      default:
        throw new Error(`${translation.unsupportedHttpMethodType}: ${httpMethod}`);
    }
  }

  private async saveFile(blob: Blob | undefined, headers: HttpHeaders) {
    const translation = await firstValueFrom(
      combineLatest([
        tr('error_no_http_blob_response'),
        tr('error_no_http_headers_response'),
        tr('file'),
        tr('error_create_blob_uri'),
        tr('file_transfered'),
      ]).pipe(
        map(([noBlob, noHeaders, file, errorCreateBlob, fileTransfered]) => ({
          noBlob,
          noHeaders,
          file,
          errorCreateBlob,
          fileTransfered,
        })),
      ),
    );

    if (!blob) {
      throw new Error(translation.noBlob);
    }

    if (!headers) {
      throw new Error(translation.noHeaders);
    }

    //const contentType = headers.get('content-type');
    let fileName = '';

    try {
      const contentDisposition = headers.get('content-disposition');
      fileName = contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim() ?? translation.file;
    } catch (error) {
      fileName = translation.file;
    }

    const blobUri = URL.createObjectURL(blob);

    if (!blobUri) {
      throw Error(translation.errorCreateBlob);
    }
    if (fileName.startsWith('"')) {
      fileName = fileName.slice(1);
    }
    if (fileName.endsWith('"')) {
      fileName = fileName.slice(0, -1);
    }

    // save file
    const anchor = document.createElement('a');
    anchor.href = blobUri;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    this.messageService.showInfoSnackbar(`${translation.fileTransfered}: ${fileName}`);
  }
}
