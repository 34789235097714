import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  EditorComponent,
  EditorFontFamilyComponent,
  EditorFontSizeComponent,
  EditorState,
  EditorView,
  Plugin,
  PluginKey,
  Schema,
  inputRules,
  schema as kendoSchema,
} from '@progress/kendo-angular-editor';
import { FontFamilyItem } from '@progress/kendo-angular-editor/common/font-family-item.interface';
import { EditorEditMode, IEditorValue, RcgContact } from '@rcg/core/models';
import { AttachmentsService, HTMLAttachmentResolverService } from '@rcg/core/services';
import { MessageService } from '@rcg/standalone/services';
import { htmlToPlainText, normalizeAndCleanHTML } from '@rcg/standalone/utils/html-utils';
import { TextSelection } from 'prosemirror-state';
import { delay, firstValueFrom, of } from 'rxjs';
import { ContactsSuggestorComponent } from '../contacts-suggestor/contacts-suggestor.component';
import { EditorFormat } from '../format';
import { KnowledgebaseSuggestorComponent } from '../knowledgebase-suggestor/knowledgebase-suggestor.component';
import { addSuggestionNodes, suggestionInputRule } from './suggestions';

const boundingRectKeys = ['top', 'left', 'width', 'height'] as const;

const dragStatusChangeProp = '__rcg_dragStatusChange';

type DragStatusChangePane = HTMLElement & {
  [dragStatusChangeProp]: ((() => void) & { thisRef: HtmlEditorComponent })[];
};

@Component({
  selector: 'rcg-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  static readonly valueUpdateDebounceTimeMs = 200;

  @Input() value!: IEditorValue;
  @Input() emlOriginalHtml: string | null = null;
  @Input() title = 'editor';
  @Input() required = true;
  @Input() readonly = false;
  @Input() editorFormat: EditorFormat = 'html';
  @Input() editorMode: EditorEditMode = 'readonly';

  @Input() editIconColor?: string;
  @Input() readonlyIconColor?: string;
  @Input() editOnClick = false;
  @Input() stopEditOnUnfocus = false;
  @Input() enableContactsInput = false;
  @Input() formatPastedLinks = true;

  @Output() inlineAttachmentsAdded = new EventEmitter<number[]>();

  @Output() valueUpdating = new EventEmitter<boolean>();

  @Output() valueChanged = new EventEmitter<IEditorValue>();
  @Output() editModeChanged = new EventEmitter<EditorEditMode>();

  @Output() contactAdded = new EventEmitter<RcgContact>();

  @HostBinding('class.fullscreen') fullscreenMode = false;

  @ViewChild('editor', { read: ElementRef }) editor?: ElementRef<HTMLElement>;
  @ViewChild('editor') editorComponent?: EditorComponent;
  @ViewChild('iframe') readonlyIframe?: ElementRef<HTMLIFrameElement>;
  @ViewChild('contactsSuggestor') contactsSuggestorComponent?: ContactsSuggestorComponent;

  @ViewChild('linkTooltip', { read: ElementRef }) linkTooltip!: ElementRef<HTMLElement>;

  private observer!: ResizeObserver;

  readonly editorFonts: FontFamilyItem[] = [
    { fontName: 'Roboto', text: 'Roboto' },
    { fontName: 'Noto Sans', text: 'Noto Sans' },
    { fontName: 'Noto Sans Mono', text: 'Noto Sans Mono' },
    { fontName: 'Arial', text: 'Arial' },
    { fontName: 'Courier New', text: 'Courier New' },
    { fontName: 'Georgia', text: 'Georgia' },
    { fontName: 'Times New Roman', text: 'Times New Roman' },
    { fontName: 'Verdana', text: 'Verdana' },
  ];

  customEditorStyles = `
    p {
      margin-bottom: 0 !important;
    }
  `;

  showOriginalEml = false;

  private readonly knowledgebaseTag = 'rcg-knowledgebase';

  schema = new Schema({
    nodes: addSuggestionNodes(
      kendoSchema.spec.nodes,
      [this.knowledgebaseTag],
      () => {
        const frames = this.editor?.nativeElement.getElementsByTagName('iframe');
        if (frames && frames.length > 0 && frames[0].contentDocument) return frames[0].contentDocument;
        throw new Error('Unable to find editor frame');
      },
      () => this.vcRef.createComponent(KnowledgebaseSuggestorComponent),
      () => this.editorComponent?.view,
    ),
    marks: kendoSchema.spec.marks,
  });

  private inputRules = inputRules({
    rules: [suggestionInputRule(/\$\$([a-z0-9\-_]*)\$\$/i, this.knowledgebaseTag)],
  });

  plugins = (defaultPlugins: Plugin[]): Plugin[] => [
    this.inputRules,
    ...(this.enableContactsInput ? [this.contactsPlugin, this.keydownPlugin] : []),
    ...defaultPlugins,
    ...(this.formatPastedLinks ?? true ? [this.pastePlugin] : []),
  ];

  contactsDropdown: boolean = false;
  contactSearch: string = '';
  contactsDropdownPosition: { [Key: string]: string } = {};

  private pane?: HTMLElement | null;

  constructor(
    private attachmentsService: AttachmentsService,
    private elementRef: ElementRef<HTMLElement>,
    private changeDetector: ChangeDetectorRef,
    private vcRef: ViewContainerRef,
    private messageService: MessageService,
    private htmlAttachmentResolver: HTMLAttachmentResolverService,
  ) {}

  ngOnInit(): void {
    this.changeEditMode(this.editorMode, true);
  }

  ngAfterViewInit(): void {
    const el = this.elementRef.nativeElement;
    this.pane = el.closest('.cdk-overlay-pane');
    if (!this.pane) return;

    const dscPane = this.pane as DragStatusChangePane;
    dscPane[dragStatusChangeProp] ??= [];

    const fn = () => {
      if (this.fullscreenMode) this.resizeFullscreen();
      else this.clearFullscreenData();
    };

    fn.thisRef = this;

    dscPane.__rcg_dragStatusChange.push(fn);

    this.observer = new ResizeObserver(() => {
      setTimeout(() => {
        this.onResize();
      });
    });
    this.observer.observe(this.pane);
  }

  ngOnDestroy(): void {
    if (this.pane) {
      this.observer?.unobserve(this.pane);
    }
    this.linkTooltip?.nativeElement?.remove();

    if (!this.pane) return;

    const dscPane = this.pane as DragStatusChangePane;
    dscPane[dragStatusChangeProp] ??= [];
    dscPane[dragStatusChangeProp] = dscPane[dragStatusChangeProp].filter((fn) => fn.thisRef !== this);
  }

  private pastePlugin = new Plugin({
    key: new PluginKey('paste-handler'),
    props: {
      handlePaste: (view: EditorView, event: ClipboardEvent) => {
        const text = event.clipboardData?.getData('text/plain') ?? '';
        if (!text) {
          return false;
        }
        const { schema, selection } = view.state;
        const linkMark = schema.marks.link;
        if (!linkMark) {
          console.warn('Schema does not have a link mark');
          return false;
        }
        const { tr } = view.state;
        let pos = selection.from;
        const urlRegex = /(https?:\/\/\S+)/g;
        const lines = text.split('\n');

        const processLine = (line: string) => {
          let lastIndex = 0;
          let match;
          const lineContent = [];
          while ((match = urlRegex.exec(line)) !== null) {
            if (match.index > lastIndex) {
              const normalText = line.slice(lastIndex, match.index);
              lineContent.push(schema.text(normalText));
            }
            const url = match[0];
            const formattedUrl = this.formatGitLabUrl(url);
            const linkNode = schema.text(formattedUrl, [linkMark.create({ href: url, target: '_blank' })]);
            lineContent.push(linkNode);
            lastIndex = match.index + url.length;
          }
          if (lastIndex < line.length) {
            const remainingText = line.slice(lastIndex);
            lineContent.push(schema.text(remainingText));
          }
          return lineContent;
        };

        if (lines.length === 1) {
          // For single-line pastes, insert inline
          const lineContent = processLine(lines[0]);
          // tr.insertText(' ', pos);
          // pos += 1;
          lineContent.forEach((node) => {
            tr.insert(pos, node);
            pos += node.nodeSize;
          });
          // tr.insertText(' ', pos);
          // pos += 1;
        } else {
          // For multi-line pastes, create paragraphs
          lines.forEach((line) => {
            const lineContent = processLine(line);
            const paragraphNode = schema.nodes.paragraph.create(null, lineContent);
            tr.insert(pos, paragraphNode);
            pos += paragraphNode.nodeSize;
          });
        }

        tr.setSelection(TextSelection.create(tr.doc, pos));
        view.dispatch(tr);
        return true;
      },
    },
  });

  private keydownPlugin = new Plugin({
    key: new PluginKey('keydown-handler'),
    props: {
      handleKeyDown: (view, event) => {
        if (this.contactsDropdown) {
          switch (event.key) {
            case 'ArrowDown':
              this.contactsSuggestorComponent?.keyboardAction('down');
              return true;

            case 'ArrowUp':
              this.contactsSuggestorComponent?.keyboardAction('up');
              return true;

            case 'Enter':
              this.contactsSuggestorComponent?.keyboardAction('enter');
              return true;
          }
        }
        return false;
      },
    },
  });

  private getWordBeforeCursor(state: EditorState): string {
    const { $from } = state.selection;
    const textBefore = $from.parent.textContent.slice(0, $from.parentOffset);
    const words = textBefore.split(/\s+/);
    return words[words.length - 1] || '';
  }

  private contactsPlugin = new Plugin({
    key: new PluginKey('contacts-dropdown'),
    view: () => ({
      update: (view) => {
        const state = view.state;
        const cursorPosition = state.selection.$anchor.pos;
        const word = this.getWordBeforeCursor(view.state);

        if (word) {
          if (/^@[^\s]*$/i.test(word)) {
            if (this.contactsDropdown === false) {
              const cursorCoords = view.coordsAtPos(cursorPosition);
              this.contactsDropdownPosition = {
                top: cursorCoords.top + 145 + 'px',
                left: cursorCoords.left + 'px',
              };
            }

            this.contactSearch = word.slice(1);
            this.contactsDropdown = true;
          } else {
            this.contactsDropdown = false;
          }
        } else {
          this.contactsDropdown = false;
        }
      },
    }),
  });

  contactSelected(contact: RcgContact | null) {
    if (!contact) return;
    const styledName = `<span style="color: #3366cc; font-weight: bold;">@${contact?.first_name} ${contact?.last_name}</span>`;
    this.contactsDropdown = false;

    if (this.editorComponent) {
      const currentValue = this.editorComponent.value || '';
      const searchText = '@' + this.contactSearch;
      const startPos = currentValue.indexOf(searchText);

      if (startPos !== -1) {
        const newValue =
          currentValue.substring(0, startPos) + styledName + '<span>&nbsp;</span>' + currentValue.substring(startPos + searchText.length);

        this.editorComponent.writeValue(newValue);
        this.value.html = newValue;
        this.editorHtmlChanged(newValue);

        this.contactAdded.emit(contact);

        setTimeout(() => {
          const editorElement = this.editor?.nativeElement;
          const iframe = editorElement?.querySelector('iframe');
          if (iframe && iframe.contentDocument) {
            const doc = iframe.contentDocument;
            const body = doc.body;

            const range = doc.createRange();

            const spans = body.getElementsByTagName('span');
            const insertedSpans = Array.from(spans).filter(
              (span) => span.textContent === `@${contact?.first_name} ${contact?.last_name}` || span.innerHTML === '&nbsp;',
            );

            if (insertedSpans.length === 2) {
              const spaceSpan = insertedSpans[1];
              range.setStartAfter(spaceSpan);
              range.collapse(true);

              const selection = doc.getSelection();
              selection?.removeAllRanges();
              selection?.addRange(range);

              iframe.contentWindow?.focus();
            }
          }
        }, 100);
      }
    }
  }

  private onResize() {
    if (this.fullscreenMode) this.resizeFullscreen();
    else this.performAutoScale();
  }

  private formatGitLabUrl(url: string): string {
    const gitLabBaseUrl = 'https://git.assist.si/';
    if (!url.startsWith(gitLabBaseUrl)) {
      return url;
    }

    const parsedUrl = new URL(url);
    const pathParts = parsedUrl.pathname.split('/').filter((part) => part);

    if (pathParts.length >= 2) {
      if (pathParts.length === 2) {
        return `${pathParts[0]}/${pathParts[1]}`;
      }

      if (pathParts[3] === 'commit' && pathParts[4]) {
        return pathParts[4].substring(0, 8);
      }

      if (pathParts[3] === 'compare' && pathParts[4]) {
        const [hash1, hash2] = pathParts[4].split('...');
        if (hash1 && hash2) {
          return `${hash1.substring(0, 8)}...${hash2.substring(0, 8)}`;
        }
      }

      if (pathParts[3] === 'merge_requests' && pathParts[4]) {
        return `!${pathParts[4]}`;
      }

      if (pathParts[3] === 'tree' && pathParts[4]) {
        return `(${pathParts[4]})`;
      }

      if (pathParts[3] === 'tags' && pathParts[4]) {
        return pathParts[4];
      }
    }

    return url;
  }
  changeEditMode(editorMode: EditorEditMode, init = false): void {
    const iframe = this.readonlyIframe?.nativeElement;

    if (editorMode === 'edit' && iframe && this.getSelection(iframe.contentWindow!)) {
      return;
    }

    if (editorMode !== 'readonly') {
      setTimeout(() => {
        this.appendedHead = false;
        this.appendHead();
      });
    }

    this.editorMode = editorMode;

    if (this.editorMode === 'edit') {
      this.editorFormat = 'html';

      if (!init) this.focusEditor();
    }

    setTimeout(() => this.editModeChanged.emit(editorMode));
  }

  changeFormatMode(format: EditorFormat): void {
    this.editorFormat = format;
    if (this.editorFormat === 'text') {
      this.editorMode = 'readonly';
    }
  }

  private appendedHead = false;

  private appendHead(tries = 1) {
    if (this.appendedHead) return;

    if (tries > 4) {
      console.error(`HTML field failed to append head after ${tries} tries.`);
      return;
    }

    if (!this.value?.html) {
      console.warn('No value found to append head in HTML editor!');
      return;
    }

    const frames = this.editor?.nativeElement.getElementsByTagName('iframe');

    if (frames && frames.length > 0) {
      const frame = frames[0];

      const parser = new DOMParser();
      const valueDom = parser.parseFromString(this.value.html, 'text/html');
      setTimeout(() => {
        try {
          const doc = frame.contentDocument;
          if (!doc) {
            throw new Error('Frame has no contentDocument');
          }

          doc.head.append(...valueDom.head.children);
          doc.oncopy = (e) => this.htmlCopied(e, false);
        } catch (e) {
          this.appendedHead = false;
          this.appendHead(tries + 1);
        }
      });
    } else {
      console.warn('No frames found to append head in HTML editor!');
      return;
    }

    this.appendedHead = true;
  }

  private htmlChangedDebounceTimeouts: number[] = [];
  private lastHtmlChangedTime = 0;

  private clearEditorHtmlChangedTimeouts() {
    while (this.htmlChangedDebounceTimeouts.length) {
      window.clearTimeout(this.htmlChangedDebounceTimeouts.pop());
    }
  }

  private _changeId = 0;

  getExtension(element: HTMLImageElement) {
    const mimeTypeRegex = /^data:([a-z]+\/[a-z]+);/i;

    const mimeType = element.src.match(mimeTypeRegex)?.[1];
    switch (mimeType) {
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpeg';
      default:
        return;
    }
  }

  editorHtmlChanged(html: string): void {
    const changeId = ++this._changeId;
    this.valueUpdating.emit(true);

    const lastChangedMsAgo = new Date().getTime() - this.lastHtmlChangedTime;

    if (this.htmlChangedDebounceTimeouts && lastChangedMsAgo < 1000) {
      this.clearEditorHtmlChangedTimeouts();
    }

    const selfTimeout = window.setTimeout(async () => {
      const lastTimeout = this.htmlChangedDebounceTimeouts.pop();
      this.clearEditorHtmlChangedTimeouts();
      if (lastTimeout) this.htmlChangedDebounceTimeouts.push(lastTimeout);

      this.lastHtmlChangedTime = new Date().getTime();
      this.unfocusedHtmlChanged = true;

      this.appendHead();
      const cleanHTML = await normalizeAndCleanHTML(html);
      this.valueChanged.emit({ ...this.value, html: cleanHTML, plain: await htmlToPlainText(cleanHTML) });

      setTimeout(() => {
        if (changeId === this._changeId) this.valueUpdating.emit(false);
      }, 10);
    }, HtmlEditorComponent.valueUpdateDebounceTimeMs);

    this.htmlChangedDebounceTimeouts.push(selfTimeout);
  }

  @HostListener('window:mousemove')
  onMouseMove() {
    const tooltipEl = this.linkTooltip?.nativeElement;
    if (!tooltipEl) return;
    tooltipEl.style.opacity = '0';
  }

  readonlyFrameLoaded(event: Event) {
    const frame = event.target as HTMLIFrameElement;

    const doc = frame?.contentDocument;
    if (!doc) return;

    doc.onmousemove = (e) => {
      const targetEl = e.target as Element;
      const tooltipEl = this.linkTooltip?.nativeElement;
      if (!tooltipEl) return;

      const anchorEl = targetEl?.closest('a');

      if (!anchorEl) {
        tooltipEl.style.opacity = '0';
        return;
      }

      e.stopPropagation();

      document.body.appendChild(tooltipEl);

      const linkContainerEl = tooltipEl.getElementsByClassName('link-container')[0] as HTMLSpanElement;
      linkContainerEl.innerText = anchorEl.href;

      const frameRect = frame.getBoundingClientRect();
      const anchorRect = anchorEl.getBoundingClientRect();
      const tooltipRect = tooltipEl.getBoundingClientRect();

      const positionOffset = {
        x: anchorRect.width / 2 - tooltipRect.width / 2,
        y: -tooltipRect.height - 2,
      };

      tooltipEl.style.left = `${frameRect.x + anchorRect.x + positionOffset.x}px`;
      tooltipEl.style.top = `${frameRect.y + anchorRect.y + positionOffset.y}px`;

      tooltipEl.style.opacity = '1';
    };

    doc.onclick = (e) => {
      if (e.target && 'closest' in e.target) {
        e.preventDefault();

        const closestLink = (e.target as HTMLElement).closest('a');

        if (closestLink?.href && e.ctrlKey) {
          window.open(closestLink.href, '_blank');
          return;
        }
      }

      if (this.editOnClick) {
        this.changeEditMode('edit');
        this.changeDetector.detectChanges();
      }
    };

    doc.oncopy = (e) => this.htmlCopied(e, true);
  }

  private unfocusTimeout?: number;
  private unfocusedHtmlChanged = false;

  unfocused(): void {
    if (!this.stopEditOnUnfocus) return;

    if (this.unfocusTimeout) {
      window.clearTimeout(this.unfocusTimeout);
    } else {
      this.unfocusedHtmlChanged = false;
    }

    this.unfocusTimeout = window.setTimeout(() => {
      this.unfocusTimeout = undefined;
      if (this.unfocusedHtmlChanged) return;
      if (document.querySelectorAll('kendo-popup, kendo-dialog').length) return;
      this.changeEditMode('readonly');
      this.changeDetector.detectChanges();
    }, 300);
  }

  toggleShowOriginalEml(): void {
    this.showOriginalEml = !this.showOriginalEml;
  }

  toggleFullScreenMode(event: MouseEvent): void {
    // event.stopPropagation()
    event?.preventDefault();

    if (this.fullscreenMode) {
      this.clearFullscreenData();
      setTimeout(() => this.performAutoScale());
    } else {
      this.resizeFullscreen();
      setTimeout(() => this.resizeFullscreen());
    }

    this.fullscreenMode = !this.fullscreenMode;
  }

  private resizeFullscreen() {
    const el = this.elementRef.nativeElement;
    const parent = el.closest('.html-field-fullscreen-parent');
    const dragged = this.pane?.classList.contains('rcg-dialog-dragged');

    if (parent) {
      const rect = parent.getBoundingClientRect();
      const rectOverrides: { [x in (typeof boundingRectKeys)[number]]?: number } = {};

      if (dragged) {
        rectOverrides.top = 64;
        rectOverrides.left = 0;
      }

      for (const p of boundingRectKeys) {
        el.style[p] = `${rectOverrides[p] ?? rect[p]}px`;
      }
    }
  }

  private clearFullscreenData() {
    const el = this.elementRef.nativeElement;

    for (const p of ['top', 'left', 'width', 'height']) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (el.style as any)[p] = '';
    }
  }

  private autoScaleTo: HTMLElement | null = null;

  autoScaleHeightToUnusedSpace(container: HTMLElement | null) {
    this.autoScaleTo = container;
    this.performAutoScale();
  }

  private _autoScaleDebounce?: number;

  private performAutoScale(tries = 0) {
    if (this._autoScaleDebounce) {
      clearTimeout(this._autoScaleDebounce);
    }

    this._autoScaleDebounce = window.setTimeout(() => this._performAutoScale(tries), 100);
  }

  private _performAutoScale(tries: number) {
    const el = this.elementRef.nativeElement;
    const container = this.autoScaleTo;

    el.style.minHeight = '';

    if (!container) return;

    const currentElHeight = el.getBoundingClientRect().height;

    const containerHeight = container.getBoundingClientRect().height;
    const containerChildrenHeights = [...container.children].map((c) => c.getBoundingClientRect().height);
    const containerChildHeightSum = containerChildrenHeights.reduce((a, b) => a + b, 0);

    const unusedHeight = containerHeight - containerChildHeightSum;

    if (unusedHeight <= 0 && tries < 10) {
      this.performAutoScale(tries + 1);
    }

    if (unusedHeight < 0) {
      el.style.minHeight = '';
      return;
    }

    el.style.minHeight = `${currentElHeight + unusedHeight}px`;
  }

  public async focusEditor() {
    let focused = false;
    let tries = 0;

    while (!focused) {
      if (tries > 50) {
        console.warn('HTML editor focus timed out after 5 seconds');
        return;
      }

      tries++;

      try {
        this.editor!.nativeElement.querySelector('iframe')!.contentDocument!.querySelector<HTMLElement>('.k-content')!.focus();
        focused = true;
      } catch (_) {
        //ignore
      }

      await firstValueFrom(of(null).pipe(delay(100)));
    }
  }

  private getSelection(window: Window) {
    if (!window.getSelection) return null;

    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    const clonedSelection = range.cloneContents();
    const div = document.createElement('div');

    div.appendChild(clonedSelection);
    return div.innerHTML ? div.innerHTML : null;
  }

  private getIframeSelection(iframe: HTMLIFrameElement | null | undefined): string | null {
    const error = (message: string) => {
      this.messageService.showErrorSnackbar(message);
      return null;
    };

    if (!iframe) return error('Napaka: Vsebina ni bila najdena');

    const html = this.getSelection(iframe.contentWindow!) ?? iframe.contentDocument?.body.innerHTML;
    if (!html) return error('Napaka: Ni besedila');

    return html;
  }

  public getReadonlyIframeSelection(): string | null {
    return this.getIframeSelection(this.readonlyIframe?.nativeElement);
  }

  public getEditorIframeSelection(): string | null {
    return this.getIframeSelection(this.editor?.nativeElement.querySelector('iframe'));
  }

  public async htmlCopied(event: ClipboardEvent, readonly: boolean) {
    const cbData = event.clipboardData;

    if (!cbData) {
      console.warn('HTML editor copy has no clipboard data!');
      return;
    }

    const selection = readonly ? this.getReadonlyIframeSelection() : this.getEditorIframeSelection();

    if (!selection) {
      console.warn('HTML editor copy has no selection!');
      return;
    }

    event.preventDefault();
    const bakedHTML = await this.htmlAttachmentResolver.bakeBlobs(selection);
    const bakedPlain = await htmlToPlainText(bakedHTML);

    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([bakedHTML], { type: 'text/html' }),
      'text/plain': new Blob([bakedPlain], { type: 'text/plain' }),
    });

    navigator.clipboard.write([clipboardItem]);
  }

  private getEditorFrameSelectionComputedStyle(): CSSStyleDeclaration | null {
    const window = this.editor?.nativeElement.querySelector('iframe')?.contentWindow;
    if (!window?.getSelection) return null;

    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    const selNode = range.cloneContents().firstElementChild ?? range.commonAncestorContainer;
    const selEl = selNode instanceof Element ? selNode : selNode.parentElement;

    if (!selEl) return null;

    return window.getComputedStyle(selEl);
  }

  private initDropdownComponentComputedStyleValue<
    DVP extends string,
    C extends { data: ({ text: string } & { [d in DVP]: string | number })[] },
  >(component: C, propName: string, dataValProp: DVP) {
    let val: string | null = null;

    Object.defineProperty(component, 'value', {
      get: () => {
        const ret = (() => {
          if (val) return val;

          const style = this.getEditorFrameSelectionComputedStyle();
          if (style) return style.getPropertyValue(propName);

          return null;
        })();

        if (!ret) return null;

        if (component.data.findIndex((item) => item[dataValProp] === ret) === -1) {
          component.data.push({ text: ret, [dataValProp]: ret } as C['data'][number]);
        }

        return ret;
      },
      set: (v) => (val = v),
    });
  }

  public fontSizeInit(component: EditorFontSizeComponent) {
    this.initDropdownComponentComputedStyleValue(component, 'font-size', 'size');
  }

  public fontFamilyInit(component: EditorFontFamilyComponent) {
    this.initDropdownComponentComputedStyleValue(component, 'font-family', 'fontName');
  }
}
