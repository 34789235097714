<rcg-dialog
  #dialog
  *ngIf="dialogConfig.dialogTitle"
  [dialogRef]="dialogRef"
  [icon]="dialogConfig.dialogIcon ?? 'description'"
  [title]="dialogConfig.dialogTitle!"
  [iconTooltip]="dialogConfig.formId ? 'Form: ' + dialogConfig.formId : undefined"
>
  <ng-container ngProjectAs="toolbar-icons">
    @for (action of actions$ | async; track $index) {
    <mat-icon
      [matTooltip]="action.tooltip | intl : { default: action.tooltip || '' }"
      matTooltipPosition="below"
      [style.color]="action.color"
      (click)="action.exec()"
    >
      {{ action.icon }}
    </mat-icon>
    }
  </ng-container>

  <div *ngIf="!formConfig" class="error">
    <div>{{ errorMessage }}</div>
  </div>

  <rcg-formly-form
    *ngIf="formConfig"
    (submitSuccess)="onSubmitSuccess($event)"
    (submitError)="onSubmitError($event)"
    (formSubmited)="onFormSubmited($event)"
    [formConfig]="formConfig"
    [focusFirstFieldOnInit]="true"
  ></rcg-formly-form>
</rcg-dialog>
