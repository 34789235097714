<kendo-editor-messages
  [addColumnAfter]="'kendo_editor_add_column_after' | intl"
  [addColumnBefore]="'kendo_editor_add_column_before' | intl"
  [addRowAfter]="'kendo_editor_add_row_after' | intl"
  [addRowBefore]="'kendo_editor_add_row_before' | intl"
  [alignCenter]="'kendo_editor_align_center' | intl"
  [alignJustify]="'kendo_editor_align_justify' | intl"
  [alignLeft]="'kendo_editor_align_left' | intl"
  [alignRight]="'kendo_editor_align_right' | intl"
  [backColor]="'kendo_editor_back_color' | intl"
  [blockquote]="'kendo_editor_blockquote' | intl"
  [bold]="'kendo_editor_bold' | intl"
  [cleanFormatting]="'kendo_editor_clean_formatting' | intl"
  [createLink]="'kendo_editor_create_link' | intl"
  [deleteColumn]="'kendo_editor_delete_column' | intl"
  [deleteRow]="'kendo_editor_delete_row' | intl"
  [deleteTable]="'kendo_editor_delete_table' | intl"
  [dialogApply]="'kendo_editor_dialog_apply' | intl"
  [dialogCancel]="'kendo_editor_dialog_cancel' | intl"
  [dialogInsert]="'kendo_editor_dialog_insert' | intl"
  [dialogUpdate]="'kendo_editor_dialog_update' | intl"
  [fileText]="'kendo_editor_file_text' | intl"
  [fileTitle]="'kendo_editor_file_title' | intl"
  [fileWebAddress]="'kendo_editor_file_web_address' | intl"
  [fontFamily]="'kendo_editor_font_family' | intl"
  [fontSize]="'kendo_editor_font_size' | intl"
  [foreColor]="'kendo_editor_fore_color' | intl"
  [format]="'kendo_editor_format' | intl"
  [imageAltText]="'kendo_editor_image_alt_text' | intl"
  [imageHeight]="'kendo_editor_image_height' | intl"
  [imageWebAddress]="'kendo_editor_image_web_address' | intl"
  [imageWidth]="'kendo_editor_image_width' | intl"
  [indent]="'kendo_editor_indent' | intl"
  [insertFile]="'kendo_editor_insert_file' | intl"
  [insertImage]="'kendo_editor_insert_image' | intl"
  [insertOrderedList]="'kendo_editor_insert_ordered_list' | intl"
  [insertTable]="'kendo_editor_insert_table' | intl"
  [insertUnorderedList]="'kendo_editor_insert_unordered_list' | intl"
  [italic]="'kendo_editor_italic' | intl"
  [linkOpenInNewWindow]="'kendo_editor_link_open_in_new_window' | intl"
  [linkText]="'kendo_editor_link_text' | intl"
  [linkTitle]="'kendo_editor_link_title' | intl"
  [linkWebAddress]="'kendo_editor_link_web_address' | intl"
  [mergeCells]="'kendo_editor_merge_cells' | intl"
  [outdent]="'kendo_editor_outdent' | intl"
  [print]="'kendo_editor_print' | intl"
  [redo]="'kendo_editor_redo' | intl"
  [selectAll]="'kendo_editor_select_all' | intl"
  [splitCell]="'kendo_editor_split_cell' | intl"
  [strikethrough]="'kendo_editor_strikethrough' | intl"
  [subscript]="'kendo_editor_subscript' | intl"
  [superscript]="'kendo_editor_superscript' | intl"
  [underline]="'kendo_editor_underline' | intl"
  [undo]="'kendo_editor_undo' | intl"
  [unlink]="'kendo_editor_unlink' | intl"
  [viewSource]="'kendo_editor_view_source' | intl"
></kendo-editor-messages>
