import { Injectable, computed, inject } from '@angular/core';
import { AuthService } from '@rcg/auth';
import { IAction } from '../models/actions';

@Injectable({
  providedIn: 'root',
})
export class ActionsUtils {
  private authService = inject(AuthService);

  async isEndUser(moduleId?: number): Promise<boolean> {
    moduleId = moduleId ?? 317;
    const tenant = this.authService.tenant();
    const isEndUser = tenant?.modules?.find((m) => m?.id === moduleId)?.sec?.is_end_user;
    if (isEndUser === undefined || isEndUser === null) {
      throw new Error(`Error checking if user is agent or end user. IsEndUser not set for moduleId ${moduleId}`);
    }
    return isEndUser;
  }

  readonly getAuthInfo = computed(() => {
    const { user, tenant } = this.authService.authInfo();

    if (!user?.id) throw new Error('No user');
    if (!tenant?.id) throw new Error('No user tenant id');
    if (!tenant?.organization?.id) throw new Error('No user organization id');

    return { user, tenant };
  });
}

export const filterAndSortActions = (actions: IAction[]): IAction[] => {
  if (!actions || actions.length === 0) return [];

  return actions
    .filter((a) => a.visible !== false)
    .sort((a, b) => {
      if (a.order === undefined && b.order === undefined) {
        return 0;
      } else if (a.order === undefined) {
        return 1;
      } else if (b.order === undefined) {
        return -1;
      } else {
        return a.order - b.order;
      }
    });
};
