import { createSelector } from '@ngrx/store';
import { selectPhoneExtensionsState } from './extensions/phone-extensions.selectors';
import { selectPhoneCallsState } from './phone-calls/phone-calls.selectors';

export interface PhoneDialogHeaderConfig {
  title: string;
  icon: string;
  hint: string;
}

export const selectPhoneDialogHeaderConfig = createSelector(
  selectPhoneExtensionsState,
  selectPhoneCallsState,
  (extensions, calls) => {
    if (extensions?.error || calls?.error) {
      return {
        title: `Napaka ${
          extensions?.error ? ' extension' : ' telefonskega klica'
        }`,
        icon: 'error',
        hint: `Napaka: ${extensions?.error ? extensions.error : calls?.error}`,
      } as PhoneDialogHeaderConfig;
    }
    if (extensions?.extensions?.length > 0 && calls?.phoneCalls?.length > 0) {
      return {
        title: 'Preveži telefonski klic',
        icon: 'swap_calls',
        hint: 'Preveži klic',
      } as PhoneDialogHeaderConfig;
    }
    if (extensions?.extensions?.length > 0 && calls?.phoneCalls?.length === 0) {
      return {
        title: 'Pokliči',
        icon: 'call',
        hint: 'Pokliči',
      } as PhoneDialogHeaderConfig;
    }
    return {
      title: '',
      icon: '',
      hint: '',
    } as PhoneDialogHeaderConfig;
  }
);
