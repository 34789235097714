<div class="container" (blur)="unfocused()">
  <div class="toolbar">
    <div>
      <ng-container *ngIf="editorMode === 'edit'">
        <button
          matTooltip="Read only"
          matTooltipPosition="above"
          mat-icon-button
          (click)="changeEditMode('readonly'); $event.preventDefault()"
        >
          <mat-icon [style.color]="readonlyIconColor">edit_off</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="editorMode === 'readonly' && !readonly">
        <button matTooltip="Edit" matTooltipPosition="above" mat-icon-button (click)="changeEditMode('edit'); $event.preventDefault()">
          <mat-icon [style.color]="editIconColor">edit</mat-icon>
        </button>
        <ng-container *ngIf="editorFormat === 'html'">
          <button matTooltip="Text" matTooltipPosition="above" mat-icon-button (click)="changeFormatMode('text'); $event.preventDefault()">
            <mat-icon>view_headline</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="editorFormat === 'text'">
          <button matTooltip="Html" matTooltipPosition="above" mat-icon-button (click)="changeFormatMode('html'); $event.preventDefault()">
            <mat-icon>html</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="emlOriginalHtml">
          <button
            [matTooltip]="showOriginalEml ? ('hide_original_eml_email' | intl) : ('show_original_eml_email' | intl)"
            matTooltipPosition="above"
            mat-icon-button
            (click)="toggleShowOriginalEml(); $event.preventDefault()"
          >
            <mat-icon [ngStyle]="{ color: showOriginalEml ? 'green' : '' }">email</mat-icon>
          </button>
        </ng-container>

        <ng-content select="[extraButtons]"></ng-content>
      </ng-container>
    </div>
    <button
      [matTooltip]="fullscreenMode ? ('close_fullscreen' | intl) : ('open_fullscreen' | intl)"
      matTooltipPosition="above"
      mat-icon-button
      (click)="toggleFullScreenMode($event)"
    >
      <mat-icon *ngIf="fullscreenMode">fullscreen_exit</mat-icon>
      <mat-icon *ngIf="!fullscreenMode">fullscreen</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="editorFormat === 'html' && editorMode === 'edit'">
    <kendo-editor
      #editor
      class="editor"
      [schema]="schema"
      [plugins]="plugins"
      [ngModel]="value | editorContent : emlOriginalHtml : editorFormat : showOriginalEml : editorMode"
      (ngModelChange)="editorHtmlChanged($event)"
      [required]="required"
      [readonly]="readonly"
      (blur)="unfocused()"
      [iframeCss]="{ content: customEditorStyles }"
    >
      <kendo-toolbar [overflow]="true">
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
        <kendo-toolbar-dropdownlist
          kendoEditorFontSize
          #fontSizeDropdown
          (ngAfterViewInit)="fontSizeInit(fontSizeDropdown)"
        ></kendo-toolbar-dropdownlist>
        <kendo-toolbar-dropdownlist
          kendoEditorFontFamily
          #fontFamilyDropdown
          [data]="editorFonts"
          (ngAfterViewInit)="fontFamilyInit(fontFamilyDropdown)"
        ></kendo-toolbar-dropdownlist>
        <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
        <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
        <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
      </kendo-toolbar>
      <rcg-html-editor-intl></rcg-html-editor-intl>
    </kendo-editor>
    <rcg-contacts-suggestor
      #contactsSuggestor
      *ngIf="contactsDropdown"
      [ngStyle]="contactsDropdownPosition"
      style="position: absolute"
      [search]="contactSearch"
      (contactSelected)="contactSelected($event)"
    ></rcg-contacts-suggestor>
  </ng-container>
  <ng-container *ngIf="editorMode === 'readonly'">
    <iframe
      #iframe
      sandbox="allow-same-origin"
      style="border: 0"
      [style.max-height]="(value ? value.html : false) ? '' : '32px'"
      src="data:text/html;charset=utf-8"
      [srcdoc]="value | editorContent : emlOriginalHtml : editorFormat : showOriginalEml : editorMode"
      (load)="readonlyFrameLoaded($event)"
    ></iframe>
  </ng-container>
</div>

<mat-card #linkTooltip class="link-tooltip">
  <div style="display: flex; align-items: center">
    <mat-icon color="primary">open_in_new</mat-icon>

    <div class="text-container">
      <span class="link-container"></span>
      <span style="opacity: 0.7">{{ 'ctrl_click_message' | intl }}</span>
    </div>
  </div>
</mat-card>

<!-- (onContentChanged)="onContentChanged($event)" -->
<!-- [modules]="{toolbar:false}" -->
