import { createAction, props } from '@ngrx/store';
import { IPhoneExtension } from '@rcg/core/models';

export const registerPhoneExtensions = createAction(
  '[Phone] Register phone extensions',
  props<{ organizationId: number; userIds: number[] }>(),
);

export const registerPhoneExtensionsSuccess = createAction(
  '[Phone] Register phone extensions success',
  props<{ extensions: IPhoneExtension[] }>(),
);

export const registerPhoneExtensionsFailed = createAction('[Phone] Register phone extensions failed', props<{ error: string }>());

export const unRegisterPhoneExtensions = createAction('[Phone] UnRegister phone extensions');

export const restartPhone = createAction('[Phone] Restart phone');

export const restartPhoneFailed = createAction('[Phone] Restart phone failed');
