<ng-container *ngIf="attachments | attachmentsFilter : displayInline as attachmentsFiltered">
  <ng-container *ngIf="attachmentsFiltered.length > 0">
    <ng-container *ngFor="let attachment of attachmentsFiltered; let i = index">
      <rcg-thumbnail
        *ngVar="attachmentIdentifier(attachment) as id"
        [selected]="i === this.selectedThumbnailIndex"
        [attachment]="attachment"
        [blobUrl]="id ? (this.blobUrls[id] | async) : undefined"
        (thumbnailClick)="onThumbnailClicked($event)"
      ></rcg-thumbnail>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="attachmentsFiltered.length === 0">
    <ng-container *ngIf="multiple; then multipleNoAttachmentsTemplate; else singleNoAttachmentTemplate"></ng-container>

    <ng-template #multipleNoAttachmentsTemplate>
      <div>{{ 'no_attachments' | intl }}</div>
    </ng-template>

    <ng-template #singleNoAttachmentTemplate>
      <div>{{ 'no_attachment' | intl }}</div>
    </ng-template>
  </ng-container>
</ng-container>
