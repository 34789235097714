import { Injectable } from '@angular/core';
import { AuthService, RcgTenant, RcgUser } from '@rcg/auth';
import { IPhoneCall, RcgListItem } from '@rcg/core/models';
import { ShortcutService } from '@rcg/core/services';
import { MessageService } from '@rcg/standalone/services';
import { Observable } from 'rxjs';
import { OnActionResult } from '../models';
import { ActionsRequestInput, FormFieldActionsRequestInput, IAction } from '../models/actions';
import { FabActions } from './action-handlers/fab-actions';
import { FormFieldsActions } from './action-handlers/form-fields-actions';
import { FormActions } from './action-handlers/forms-actions';
import { HelpdeskActions } from './action-handlers/helpdesk-actions';
import { HttpRequestActions } from './action-handlers/http-action';
import { MutationActions } from './action-handlers/mutation-actions';
import { PhoneActions } from './action-handlers/phone-actions';

export interface IOpenFormInput {
  formId: number;
  recordId: number;
  title: string;
  parentId?: number;
}

/* 
   Actions facade service - clients can call only this service for actions, not separated action handlers
*/

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  constructor(
    private messageService: MessageService,
    private helpdeskActions: HelpdeskActions,
    private httpActions: HttpRequestActions,
    private phoneActions: PhoneActions,
    private formActions: FormActions,
    private mutationActions: MutationActions,
    private fabActions: FabActions,
    private formFieldsActions: FormFieldsActions,
    shortcutService: ShortcutService,
    authService: AuthService,
  ) {
    shortcutService.registerShortcut({
      category: 'ServiceDesk',
      id: 'addEvent',
      icon: 'add',
      nameTr: 'shortcut_servicedesk_add_event_name',
      descriptionTr: 'shortcut_servicedesk_add_event_description',
      preventDefault: true,
      onTrigger: async () => this.addNewHelpdeskItem(authService.user()!, authService.tenant()!, null),
    });
  }

  refreshFormActions() {
    this.formActions.refreshFormActions();
  }

  getFormActions(input: ActionsRequestInput): Observable<IAction[]> {
    return this.formActions.getFormActions(input);
  }

  getFormFieldActions(input: FormFieldActionsRequestInput): Observable<IAction[]> {
    return this.formFieldsActions.getFormFieldActions(input);
  }

  async executeAction(action: IAction, onActionResult?: OnActionResult): Promise<void> {
    if (!action || !action.enabled === true || !action.visible === true || !action.name) {
      this.messageService.showErrorSnackbar(
        `Wrong action parameters. Action name: ${action?.name} Enabled: ${action?.enabled} Visible: ${action?.visible}`,
      );
      return;
    }

    try {
      // old or custom action handler
      if (!action.config || Object.keys(action.config).length === 0) {
        const record = action?.record;
        const data = action?.data;

        switch (action.name) {
          case 'add-helpdesk-item':
            if (!data?.user || !data.tenant) throw new Error('Action data missing user or tenant');
            await this.helpdeskActions.addNewHelpdeskItem(data.user, data.tenant, data?.phoneCall);
            return;
          case 'link-phone-call':
            if (!record || !data?.user || !data?.tenant) return;
            await this.phoneActions.linkPhoneCall(record as IPhoneCall, data.user, data.tenant);
            return;
          case 'link-active-phone-call':
            // todo: implement
            break;
          case 'add-knowledgebase':
            if (!data?.tenant) throw new Error('Action data missing tenant');
            await this.helpdeskActions.addNewKnowledgeBaseItem(data.tenant);
            return;
          case 'add-problem':
            if (!data?.tenant) throw new Error('Action data missing tenant');
            await this.helpdeskActions.addNewProblem(data.tenant);
            return;
          case 'add-installed-equipment':
            if (!data?.tenant) throw new Error('Action data missing tenant');
            await this.helpdeskActions.addInstalledEquipment(data.tenant);
            return;
          case 'add-warehouse':
            await this.helpdeskActions.addWarehouse();
            return;
        }
      }

      // new actions
      switch (action.method) {
        case 'form-dialog':
          await this.formActions.openFormDialog(action, (result) => onActionResult?.(result));
          break;
        case 'confirm-mutation':
          await this.mutationActions.executeConfirmMutation(action);
          break;
        case 'mutation':
          await this.mutationActions.executeMutation(action);
          break;
        case 'http-request':
          await this.httpActions.executeHttpRequest(action);
          break;
        default:
          this.messageService.showErrorSnackbar(`Action not supported: ${action.name}}`);
      }
    } catch (error) {
      this.messageService.showErrorSnackbar(`Action error:`, (error as Error | undefined)?.message || error?.toString());
    }
  }

  /*
     Custom actions
  */

  getFabActions(
    tenant: RcgTenant,
    category: string,
    selectedHelpdeskItem: RcgListItem | null,
    isEndUser: boolean,
    moduleId: number,
  ): {
    alwaysExpanded: boolean;
    actions: IAction[];
  } {
    return this.fabActions.getFabActions(tenant, category, selectedHelpdeskItem, isEndUser, moduleId);
  }

  async addNewHelpdeskItem(user: RcgUser, tenant: RcgTenant, phoneCall: IPhoneCall | null) {
    return await this.helpdeskActions.addNewHelpdeskItem(user, tenant, phoneCall);
  }

  async openFormAction(input: IOpenFormInput, dialogId: string): Promise<void> {
    return await this.formActions.openFormAction(input, dialogId);
  }

  async makePhoneCall(phoneCall: IPhoneCall) {
    await this.phoneActions.makePhoneCall(phoneCall);
  }
}
