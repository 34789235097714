@if (attachment) {
<ng-container *ngTemplateOutlet="hasAttachmentTemplate"></ng-container>
} @else {
<mat-spinner [diameter]="24"></mat-spinner>
}

<ng-template #hasAttachmentTemplate>
  <ng-container [ngSwitch]="blobUrlState">
    <mat-spinner *ngSwitchCase="'loading'" [diameter]="24"></mat-spinner>

    <img
      *ngSwitchCase="'loaded'"
      class="thumbnail-image"
      [src]="strBlobUrl | trust : 'url'"
      [matTooltip]="
        (attachmentFilename(this.attachment) ?? '') + (this.attachment.inline ? '\n(' + ('inline_attachment' | intl) + ')' : '')
      "
      matTooltipClass="rcg-multiline-mat-tooltip"
      matTooltipPosition="below"
      (click)="onThumbnailClicked(attachment)"
    />

    <div
      *ngSwitchDefault
      class="no-thumbnail-image"
      [matTooltip]="
        (attachmentFilename(this.attachment) ?? '') + (this.attachment.inline ? '\n(' + ('inline_attachment' | intl) + ')' : '')
      "
      matTooltipClass="rcg-multiline-mat-tooltip"
      matTooltipPosition="below"
      (click)="onThumbnailClicked(attachment)"
    >
      <mat-icon>{{ isEmlAttachment(attachment) ? 'attach_email' : 'attach_file' }}</mat-icon>
    </div>
  </ng-container>
</ng-template>
