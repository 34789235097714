/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable, inject } from '@angular/core';
import { RcgTenant, RcgUser } from '@rcg/auth';
import { IOrganization, IPhoneCall } from '@rcg/core/models';
import { FormDialogService } from '@rcg/forms/services';
import {
  SupportedForm,
  getHelpdeskFormId,
  getInstalledEquipmentFormId,
  getKnowledgeBaseFormId,
  getProblemFormId,
} from '@rcg/forms/utils/supported-forms-utils';
import { tr } from '@rcg/intl';
import { PhoneService } from '@rcg/phone/services/phone.service';
import { MessageService } from '@rcg/standalone/services';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ActionsUtils } from '../actions-utils';

// Todo: refactor to server actions
/* Old actions
 */
@Injectable({
  providedIn: 'root',
})
export class HelpdeskActions {
  private messageService = inject(MessageService);
  private formDialogService = inject(FormDialogService);
  private phoneService = inject(PhoneService);
  private actionsUtils = inject(ActionsUtils);

  async addNewHelpdeskItem(
    user: RcgUser,
    tenant: RcgTenant,
    phoneCall: IPhoneCall | null | undefined,
  ): Promise<{ id?: number } | undefined> {
    try {
      const organization = tenant.organization;

      const isEndUser = await this.actionsUtils.isEndUser();

      const formId = getHelpdeskFormId(tenant.id, isEndUser);

      // prefill common to all users
      let prefillData = {
        organization_id: organization.id, // if hidden
        organization: {
          id: organization.id,
          long_name: organization.name,
        },
        form_id: formId,
        tenant_id: tenant.id,
      };

      if (!isEndUser) {
        prefillData = {
          ...prefillData,
          ...{
            owner_id: user.id, // if hidden
            owner: {
              full_name: user.fullName,
              id: user.id,
            },
          },
        };
      }

      if (isEndUser) {
        const response = tenant.userContacts.length ? tenant.userContacts[0] : null;
        if (!response?.id) throw new Error('Error getting prefill data for customer, contact. No server data');
        prefillData = {
          ...prefillData,
          ...{
            contact: {
              id: response.id,
              full_name: `${response.last_name} ${response.first_name}`,
            },
            contact_id: response.id,
            customer: response.employer?.id
              ? {
                  id: response.employer?.id,
                  long_name: response.employer?.name,
                }
              : null,
            customer_id: response.employer?.id,
          },
        };
      }
      if (!isEndUser && phoneCall && phoneCall?.id != null) {
        prefillData = {
          ...prefillData,
          ...{
            short_description: phoneCall?.is_inbound ? phoneCall?.caller : phoneCall?.reciever,
            data: { phone_call_number: phoneCall?.is_inbound ? phoneCall?.caller : phoneCall?.reciever },
            origin_id: 3,
            link_to_record: [{ id: phoneCall?.id, table: 'TEL' }],
          },
        };
      }
      if (!isEndUser && phoneCall && phoneCall.contact_id) {
        // add customer and contact automatically from phone call

        const contactDetail = await firstValueFrom(this.phoneService.getContactDetail(phoneCall.contact_id!));
        if (contactDetail) {
          if (contactDetail.employer?.id && (contactDetail.employer?.long_name?.trim() || contactDetail.employer?.short_name?.trim())) {
            const customer: Partial<IOrganization> = {
              id: contactDetail.employer.id,
              long_name: contactDetail.employer.long_name ?? contactDetail.employer.short_name,
            };
            prefillData = {
              ...prefillData,
              ...{
                customer: customer,
              },
            };
          }

          if (contactDetail.id && (contactDetail?.first_name?.trim() || contactDetail?.last_name?.trim() || contactDetail?.email?.trim())) {
            const contact = {
              id: contactDetail.id,
              full_name:
                contactDetail?.first_name || contactDetail?.last_name
                  ? `${contactDetail?.first_name ?? ''} ${contactDetail?.last_name ?? ''}`
                  : contactDetail?.email ?? '',
            };
            prefillData = {
              ...prefillData,
              ...{
                contact: contact,
              },
            };
          }
        }
      }

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (res) => {
        await lastValueFrom(
          this.formDialogService.openFormWithResult(
            {
              formId: formId,
              formMode: 'insert',
              dialogTitle: await firstValueFrom(tr('add_event')),
              prefillData: prefillData,
              onSubmitSuccessAction: ({ data }) => res(data),
            },
            `addNewHelpdeskItem${formId}`,
          ),
        );

        res(undefined);
      });
    } catch (error) {
      this.messageService.showErrorSnackbar(
        'Open form error',
        (error as Error | undefined)?.message ?? error?.toString() ?? 'Unknown error',
      );
      return undefined;
    }
  }

  async addNewKnowledgeBaseItem(tenant: RcgTenant) {
    try {
      const formId = getKnowledgeBaseFormId(tenant.id);

      // prefill common to all users
      const prefillData = {
        form_id: formId,
        tenant_id: tenant.id,
      };

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (res) => {
        await lastValueFrom(
          this.formDialogService.openFormWithResult(
            {
              formId: formId,
              formMode: 'insert',
              dialogTitle: await firstValueFrom(tr('add_knowledge_base')),
              prefillData: prefillData,
              onSubmitSuccessAction: ({ data }) => res(data),
            },
            `addNewknowledgeBase${formId}`,
          ),
        );

        res(undefined);
      });
    } catch (error) {
      this.messageService.showErrorSnackbar(
        'Open form error',
        (error as Error | undefined)?.message ?? error?.toString() ?? 'Unknown error',
      );
      return undefined;
    }
  }

  async addNewProblem(tenant: RcgTenant) {
    try {
      const formId = getProblemFormId(tenant.id);

      // prefill common to all users
      const prefillData = {
        form_id: formId,
        tenant_id: tenant.id,
      };

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (res) => {
        await lastValueFrom(
          this.formDialogService.openFormWithResult(
            {
              formId: formId,
              formMode: 'insert',
              dialogTitle: await firstValueFrom(tr('add_problem')),
              prefillData: prefillData,
              onSubmitSuccessAction: ({ data }) => res(data),
            },
            `addNewProblem${formId}`,
          ),
        );

        res(undefined);
      });
    } catch (error) {
      this.messageService.showErrorSnackbar(
        'Open form error',
        (error as Error | undefined)?.message ?? error?.toString() ?? 'Unknown error',
      );
      return undefined;
    }
  }

  async addInstalledEquipment(tenant: RcgTenant) {
    if (tenant.isEndUser) {
      this.messageService.showErrorSnackbar('Open form error', 'No permissions.');
      return;
    }

    const formId = getInstalledEquipmentFormId(tenant.id);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res) => {
      await lastValueFrom(
        this.formDialogService.openFormWithResult(
          {
            formId: formId,
            formMode: 'insert',
            dialogTitle: await firstValueFrom(tr('installed_equipment')),
            prefillData: {
              form_id: formId,
              tenant_id: tenant.id,
            },
            onSubmitSuccessAction: ({ data }) => res(data),
          },
          `addInstalledEquipment${formId}`,
        ),
      );

      res(undefined);
    });
  }

  async addWarehouse() {
    const formId = SupportedForm.warehouse;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res) => {
      await lastValueFrom(
        this.formDialogService.openFormWithResult(
          {
            formId: formId,
            formMode: 'insert',
            dialogTitle: await firstValueFrom(tr('warehouse')),
            prefillData: {
              form_id: formId,
            },
            onSubmitSuccessAction: ({ data }) => res(data),
          },
          `addNewProblem${formId}`,
        ),
      );

      res(undefined);
    });
  }
}
