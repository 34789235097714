import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { IFormSubmitPayload } from '@rcg/core/models';

@Component({
  selector: 'rcg-formly-custom-form',
  templateUrl: './formly-custom-form.component.html',
  styleUrls: ['./formly-custom-form.component.scss'],
})
export class FormlyCustomFormComponent {
  @HostBinding('class.bootstrap-styled') private bootstrapStyled = true; //! Required for styling to work

  @Input() model: Record<string, unknown> | null = null;
  @Input() fields: FormlyFieldConfig[] = [];
  @Input() formOptions: FormlyFormOptions | null = null;

  @Input() title?: string | null = null;
  @Input() allowSubmitOnPristine?: boolean = false;
  @Input() hideButtons: boolean = false;

  @Output() submitForm = new EventEmitter<IFormSubmitPayload>();

  form: UntypedFormGroup = new UntypedFormGroup({});

  onSubmitForm() {
    if (this.form.valid) {
      this.submitForm.emit({ model: this.model, form: this.form });
    }
  }
}
