import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@rcg/auth';
import { IContact, IPhoneAction, IPhoneCall, IPhoneExtension } from '@rcg/core/models';
import { GraphqlClientService } from '@rcg/graphql';
import { Observable, distinctUntilChanged, firstValueFrom, from, map, shareReplay, switchMap, throwError } from 'rxjs';
import { getcontactQuery } from '../gql/contacts.gql';
import { organizationPhoneCallsSubscription, searchOrganizationPhoneCallsSubscription } from '../gql/phone-calls.gql';
import { organizationPhoneExtensionsSubscription } from '../gql/phone-extensions';
import { linkPhoneCallMutation, phoneCallMutation, phoneCallSubscription, phoneExtensionsSubscription } from '../gql/phone.gql';
import { registerPhoneExtensions, restartPhone, unRegisterPhoneExtensions } from '../store/extensions/phone-extensions.actions';
import { selectActivePhoneExtension, selectPhoneExtensionsError } from '../store/extensions/phone-extensions.selectors';
import { clearTestPhoneCall, setTestPhoneCall } from '../store/phone-calls/phone-calls.actions';
import { selectActivePhoneCall, selectPhoneCallsError } from '../store/phone-calls/phone-calls.selectors';
import { selectPhoneDialogHeaderConfig } from '../store/phone-dialog.selectors';

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  constructor(private graphQlClient: GraphqlClientService, private store: Store, private authService: AuthService) {}

  isPhoneEnabled$(): Observable<boolean> {
    return this.authService.loggedInAuthState$.pipe(
      map((as) => as?.tenant?.phoneEnabled ?? false),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  activePhoneExtension$ = this.store.select(selectActivePhoneExtension);

  phoneExtensionError$ = this.store.select(selectPhoneExtensionsError);

  activePhoneCall$ = this.store.select(selectActivePhoneCall);

  phonCallError$ = this.store.select(selectPhoneCallsError);

  phonedialogHeaderConfig$ = this.store.select(selectPhoneDialogHeaderConfig);

  registerPhone(organizationId: number, userId: number) {
    this.store.dispatch(
      registerPhoneExtensions({
        organizationId: organizationId,
        userIds: [userId],
      }),
    );
  }

  unRegisterPhone() {
    this.store.dispatch(unRegisterPhoneExtensions());
  }

  restartPhone() {
    this.store.dispatch(restartPhone());
  }

  subscribeToPhoneExtensions(organizationId: number, userIds: number[]): Observable<IPhoneExtension[]> {
    return this.graphQlClient
      .subscribe<{ data?: IPhoneExtension[] }>({
        query: phoneExtensionsSubscription,
        variables: {
          user_ids: [...userIds],
          organization_id: organizationId,
        },
      })
      .pipe(
        map((result) => {
          if (!result?.data) {
            throw new Error('No data response from server');
          }
          return result.data;
        }),
      );
  }

  subscribeToPhoneCalls(organizationId: number, extensionIds: string[]): Observable<IPhoneCall[]> {
    return this.graphQlClient
      .subscribe<{ data?: IPhoneCall[] }>({
        query: phoneCallSubscription,
        variables: {
          limit: 1,
          organization_id: organizationId,
          extensions: [...extensionIds],
        },
      })
      .pipe(
        map((result) => {
          if (!result?.data) {
            throw new Error('No data response from server');
          }
          return result.data;
        }),
      );
  }

  subscribeToOrganizationPhoneExtensions(limit = 100): Observable<IPhoneExtension[]> {
    return this.authService.loggedInAuthState$.pipe(
      switchMap((as) => {
        const organizationId = as?.tenant?.organization?.id;
        if (!organizationId) {
          return throwError(() => new Error('No organization id'));
        }
        return this.graphQlClient
          .subscribe<{ data?: IPhoneExtension[] }>({
            query: organizationPhoneExtensionsSubscription,
            variables: {
              limit: limit,
              organization_id: organizationId,
            },
          })
          .pipe(
            map((result) => {
              if (!result?.data) {
                throw new Error('No data response from server');
              }
              return result.data;
            }),
          );
      }),
    );
  }

  subscribeToOrganizationPhoneCalls(
    organizationId: number,
    offset: number,
    limit: number,
    search: string | null,
  ): Observable<IPhoneCall[]> {
    const trimedSearch = search?.trim();
    const query = trimedSearch ? searchOrganizationPhoneCallsSubscription : organizationPhoneCallsSubscription;
    const variables = trimedSearch
      ? {
          offset: offset,
          limit: limit,
          organization_id: organizationId,
          search: `%${trimedSearch}%`,
        }
      : {
          offset: offset,
          limit: limit,
          organization_id: organizationId,
        };

    return this.graphQlClient
      .subscribe<{ data?: IPhoneCall[] }>({
        query: query,
        variables: variables,
      })
      .pipe(
        map((result) => {
          if (!result?.data) {
            throw new Error('No data response from server');
          }
          return result.data;
        }),
      );
  }

  async phoneCallCommand(action: IPhoneAction): Promise<void> {
    if (!(action.command === 'answer' || action.command === 'decline' || action.command === 'end' || action.command === 'transfer')) {
      throw `Napačna akcija ${action.command} za telefonijo. Podprte akcije: answer, decline, end, transfer`;
    }

    const command = action.command;
    const call = action.phoneCall;
    let extension: string | undefined;
    let dialTo: string | undefined;

    if (action.command === 'transfer') {
      extension = action.extension;
      dialTo = action.dialTo;
    } else {
      const isReceiver = call.reciever === (call.is_inbound ? call.reciever : call.caller);
      extension = isReceiver ? call.reciever : call.caller;
      dialTo = isReceiver ? call.caller : call.reciever;
    }

    try {
      const result = await firstValueFrom(
        this.graphQlClient.mutate({
          mutation: phoneCallMutation,
          variables: {
            serverId: call.server_id,
            organization_id: call.organization_id,
            call_id: call.call_id,
            call_connection_id: call.call_connection_id,
            action: command,
            extension: extension,
            dial_to: dialTo,
          },
        }),
      );
      this._validateMutationResult(result);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message: string = error?.message ?? '';
      if (message.includes('"single_ext"')) {
        return;
      }
      throw error;
    }
  }

  getCallNumberForMakeCall(phoneCall: IPhoneCall): string | null {
    if (!phoneCall?.id) {
      return null;
    }
    const maxExtensionLength = 5;
    const caller = phoneCall.caller?.trim();
    if (caller && caller.length > maxExtensionLength) {
      return caller;
    }
    const reciever = phoneCall.reciever?.trim();
    if (reciever && reciever.length > maxExtensionLength) {
      return reciever;
    }
    return null;
  }

  async makePhoneCall(callNumber: string, phoneExtension?: IPhoneExtension) {
    if (!callNumber) {
      throw new Error('Call number not provided');
    }

    const extension = phoneExtension?.id ? phoneExtension : await firstValueFrom(this.activePhoneExtension$);
    if (!extension?.id) {
      window.location.href = `tel:${callNumber}`;
      return;
    }

    if (!extension?.server_id) {
      throw new Error('Server id not exists in extension');
    }

    if (!extension?.organization_id) {
      throw new Error('Organization id not exists in extension');
    }

    try {
      const result = await firstValueFrom(
        this.graphQlClient.mutate({
          mutation: phoneCallMutation,
          variables: {
            action: 'makecall',
            serverId: extension.server_id!,
            organization_id: extension.organization_id!,
            call_id: 0,
            call_connection_id: 0,
            extension: extension.id!,
            dial_to: callNumber,
            // linkToRecord: { // ni potrebe za hasuro?
            //   eventNo: call?.assistRecordId,
            //   formName: call?.assistFormName,
            // },
          },
        }),
      );
      this._validateMutationResult(result);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message: string = error?.message ?? '';
      if (message.includes('"single_ext"')) {
        return; // ignore this message
      }
      throw error;
    }
  }

  async linkPhoneCall(call: IPhoneCall, link_to_record: { id: number; table: string }[]) {
    await firstValueFrom(
      this.graphQlClient.mutate({
        mutation: linkPhoneCallMutation,
        variables: {
          call_connection_id: call.call_connection_id,
          call_id: call.call_id,
          history_id_of_the_call: call.history_id_of_the_call,
          server_id: call.server_id,
          link_to_record,
        },
      }),
    );
  }

  getContactDetail(contactId: number): Observable<IContact | null> {
    return from(
      this.graphQlClient
        .query<{ data?: IContact }>({
          query: getcontactQuery,
          variables: {
            id: contactId,
          },
        })
        .pipe(
          map((result) => {
            if (!result?.data) {
              return null;
            }
            return result.data;
          }),
        ),
    );
  }

  setTestPhoneCall(phoneCal: IPhoneCall) {
    this.store.dispatch(setTestPhoneCall({ phoneCal: phoneCal }));
  }

  clearTestPhoneCall() {
    this.store.dispatch(clearTestPhoneCall());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _validateMutationResult(result: any): void {
    if (!result?.data) {
      throw 'No server data response';
    }
    const affectedRows = result.data?.affected_rows;

    if (affectedRows !== 1) {
      throw new Error(`No affected rows. Affected rows result: ${affectedRows}`);
    }
  }

  // async dismissPhoneCall(call: IPhoneCall): Promise<void> {
  //   const result = await firstValueFrom(
  //     this.graphQlClient.mutate<any>({
  //       mutation: dismissPhoneCallMutation,
  //       variables: {
  //         id: call?.id,
  //       },
  //     })
  //   );
  //   this._validateMutationResult(result);
  // }

  // async updateCallNotes(call: IPhoneCall, notes: string): Promise<void> {
  //   const result = await firstValueFrom(
  //     this.graphQlClient.mutate<any>({
  //       mutation: updatePhoneCallNotesMutation,
  //       variables: {
  //         id: call.id,
  //         notes: notes,
  //       },
  //     })
  //   );
  //   this._validateMutationResult(result);
  // }
}
