import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as extensionsReducer from './extensions/phone-extensions.reducer';
import * as phoneCallsReducer from './phone-calls/phone-calls.reducer';

export interface PhoneState {
  extensions: extensionsReducer.PhoneExtensionsState;
  phoneCalls: phoneCallsReducer.PhoneCallsState;
}

export const phoneReducers: ActionReducerMap<PhoneState> = {
  extensions: extensionsReducer.phoneExtensionsReducer,
  phoneCalls: phoneCallsReducer.phoneCallsReducer,
};

export const selectPhoneFeature = createFeatureSelector<PhoneState>('phone');

export const selectPhoneState = createSelector(selectPhoneFeature, (state) => {
  return state;
});
