import { createAction, props } from '@ngrx/store';
import { IPhoneCall } from '@rcg/core/models';

export const registerToPhoneCalls = createAction(
  '[Phone] Register to phone calls',
  props<{ organizationId: number; extensionIds: string[] }>(),
);

export const registerToPhoneCallsSuccess = createAction('[Phone] Register to phone calls success', props<{ phoneCals: IPhoneCall[] }>());

export const registerToPhoneCallsFailed = createAction('[Phone] Register to phone calls failed', props<{ error: string }>());

export const unRegisterFromPhoneCalls = createAction('[Phone] UnRegister from phone calls');

export const setTestPhoneCall = createAction('[Phone] Set test phone cal number', props<{ phoneCal: IPhoneCall }>());

export const clearTestPhoneCall = createAction('[Phone] Clear test phone call number');
