import { Injectable, inject } from '@angular/core';
import { GraphqlClientService } from '@rcg/graphql';
import { tr } from '@rcg/intl';
import { MessageService } from '@rcg/standalone/services';
import { gql } from 'apollo-angular';
import * as dot from 'dot-object';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { ActionDialogConfig, ConfirmMutationActionConfig, GqlExpectedResult, IAction, MutationActionConfig } from '../../models/actions';
import { FormActions } from './forms-actions';

@Injectable({
  providedIn: 'root',
})
export class MutationActions {
  private messageService = inject(MessageService);
  private graphQlClient = inject(GraphqlClientService);
  private formActions = inject(FormActions);

  async executeConfirmMutation(action: IAction) {
    const config = action.config as ConfirmMutationActionConfig;
    const dialog = config?.dialog as ActionDialogConfig;

    const translation = await firstValueFrom(
      combineLatest([
        tr('missing_gql_mutation_or_confirm_dialog_settings'),
        tr(dialog.title ?? 'form'),
        tr(dialog.confirmText ?? 'confirm'),
        tr(dialog.cancelText ?? 'cancel'),
        tr(dialog.message ?? 'action_dialog_confirmation'),
      ]).pipe(
        map(([missingSettings, dialogTitle, dialogConfirmText, dialogCancelText, dialogMessage]) => ({
          missingSettings,
          dialogTitle,
          dialogConfirmText,
          dialogCancelText,
          dialogMessage,
        })),
      ),
    );

    if (!config.graphql?.mutation || !config?.dialog) {
      throw new Error(translation.missingSettings);
    }

    const result = await this.messageService.confirmDialogAsync({
      icon: dialog.icon ?? 'warning',
      title: translation.dialogTitle,
      message: translation.dialogMessage,
      cancelText: translation.dialogCancelText,
      confirmText: translation.dialogConfirmText,
    });

    if (result === true) {
      await this.executeMutation(action);
    }
  }

  async executeMutation(action: IAction) {
    const config = action.config as MutationActionConfig;

    const translation = await firstValueFrom(
      combineLatest([
        tr('missing_graphql_mutation'),
        tr('empty_server_response'),
        tr(config?.graphql?.expectedResult?.errorMessage ?? 'hasura_permission_error'),
        tr(action.successMessage ?? 'action_success'),
        tr(action.errorMessage ?? 'action_error'),
      ]).pipe(
        map(([missingGqlMutation, emptyServerResponse, gqlExpectedResult, actionSuccessMessage, actionErrorMessage]) => ({
          missingGqlMutation,
          emptyServerResponse,
          gqlExpectedResult,
          actionSuccessMessage,
          actionErrorMessage,
        })),
      ),
    );

    if (!config?.graphql?.mutation) {
      throw new Error(translation.missingGqlMutation);
    }

    const gqlConfig = config!.graphql;

    const response = await firstValueFrom(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.graphQlClient.mutate<any>({
        mutation: gql(gqlConfig.mutation),
        variables: gqlConfig.variables ? gqlConfig.variables : {},
      }),
    );

    if (!response) {
      throw new Error(translation.emptyServerResponse);
    }

    if (
      gqlConfig.expectedResult?.path &&
      gqlConfig.expectedResult?.operator &&
      !this.isValidGqlResponse(gqlConfig.expectedResult, response)
    ) {
      throw new Error(translation.gqlExpectedResult);
    }

    this.messageService.showInfoSnackbar(translation.actionSuccessMessage);

    await this.formActions.onAfterSuccessAction(action);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isValidGqlResponse(expectedResult: GqlExpectedResult, response: any): boolean {
    const path = expectedResult.path?.trim() ?? '';
    const operator = expectedResult.operator?.trim() ?? '';

    const value = dot.pick(path, response);
    const isNull = value === undefined || value === null;

    switch (operator) {
      case 'gt_0':
        return !isNull && typeof value === 'number' && value > 0;
      case 'notNull':
        return !isNull;
      default:
        throw new Error(`Gql Operator ${operator} for checking server response is not implemented`);
    }
  }
}
