import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ATTACHMENT_BLOB_URL_LOADING, Attachment, AttachmentBlobUrls, EmlAttachment } from '@rcg/core/models';
import { attachmentIdentifier } from '@rcg/core/utils/attachment';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'rcg-thumbnails-container',
  templateUrl: './thumbnails-container.component.html',
  styleUrls: ['./thumbnails-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailsContainerComponent<T extends Attachment | EmlAttachment> {
  readonly attachmentIdentifier = attachmentIdentifier;

  @Output() thumbnailClick = new EventEmitter<T>();

  @Input() attachments: T[] = [];
  @Input() blobUrls: AttachmentBlobUrls = {};
  @Input() selectedThumbnailIndex?: number;
  @Input() multiple?: boolean;
  @Input() displayInline?: boolean = false;

  constructor(private elRef: ElementRef<HTMLElement>) {}

  @HostListener('wheel', ['$event'])
  onWheelScrolled(e: WheelEvent) {
    e.preventDefault();

    this.elRef.nativeElement.scrollBy({
      left: e.deltaY,
      behavior: 'smooth',
    });
  }

  onThumbnailClicked(attachment: T) {
    this.thumbnailClick.emit(attachment);
  }

  getBlobUri$(id: number | `${number}/${string}`): Observable<string | undefined> {
    return this.blobUrls[id].pipe(map((bu) => (bu && bu !== ATTACHMENT_BLOB_URL_LOADING ? bu : undefined)));
  }
}
