import { createSelector } from '@ngrx/store';
import { selectPhoneFeature } from '../index';

export const selectPhoneCallsState = createSelector(selectPhoneFeature, (state) => {
  return state.phoneCalls;
});

export const selectPhoneCallsInitialized = createSelector(selectPhoneCallsState, (state) => {
  return state.initialized;
});

export const selectPhoneCalls = createSelector(selectPhoneCallsState, (state) => {
  return state.phoneCalls;
});

export const selectActivePhoneCall = createSelector(selectPhoneCalls, (calls) => {
  return calls.length > 0 ? calls[0] : null;
});

export const selectPhoneCallsLoading = createSelector(selectPhoneCallsState, (state) => {
  return state.loading;
});

export const selectPhoneCallsError = createSelector(selectPhoneCallsState, (state) => {
  return state.error;
});
