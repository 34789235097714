import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from '@rcg/core/models';
import { RouterStateService } from '@rcg/core/services';
import { TenantId } from '@rcg/core/utils/tenant-ids';
import { FormDialogService } from '@rcg/forms/services';
import { GraphqlClientService } from '@rcg/graphql';
import { tr } from '@rcg/intl';
import { MessageService } from '@rcg/standalone/services';
import { Observable, Subject, combineLatest, firstValueFrom, lastValueFrom, map, startWith, switchMap, throwError } from 'rxjs';
import { formActionsQuery } from '../../gql/actions';
import { OnActionResult } from '../../models/action.result';
import { ActionDialogConfig, ActionsRequestInput, FormActionConfig, IAction } from '../../models/actions';
import { ActionsUtils, filterAndSortActions } from '../actions-utils';
import { IOpenFormInput } from '../actions.service';

@Injectable({
  providedIn: 'root',
})
export class FormActions {
  private messageService = inject(MessageService);
  private graphQlClient = inject(GraphqlClientService);
  private formDialogService = inject(FormDialogService);
  private actionsUtils = inject(ActionsUtils);
  private routerState = inject(RouterStateService);
  private router = inject(Router);

  private refreshFormActions$ = new Subject<void>();

  refreshFormActions() {
    this.refreshFormActions$.next();
  }

  async onAfterSuccessAction(action: IAction): Promise<void> {
    // refresh
    if (action.refreshOnSuccess !== false) {
      this.refreshFormActions();
    }

    // navigate
    const navigate = action.navigateOnSuccess;
    if (navigate?.path && navigate.path.length > 0) {
      const queryParams =
        navigate.useCurrentQueryParams === true ? await firstValueFrom(this.routerState.queryParams$) : navigate.queryparams;

      this.router.navigate(navigate!.path!, {
        queryParams: queryParams ?? {},
      });
    }
  }

  getFormActions(input: ActionsRequestInput): Observable<IAction[]> {
    return this.refreshFormActions$.asObservable().pipe(
      startWith(null),
      switchMap(() => {
        if (!input || !input.tenantId || !input.organizationId || !input.recordId || !input.formId) {
          const message = `Missing required actions parameters: ${!input.tenantId ? 'tenatId, ' : ''}${
            !input.recordId ? 'recordId, ' : ''
          }${!input.organizationId ? 'organizationId, ' : ''}${!input.formId ? 'formId, ' : ''}`;
          return throwError(() => message);
        }
        return (
          this.graphQlClient
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .query<any>({
              query: formActionsQuery,
              variables: {
                tenantId: input.tenantId,
                organizationId: input.organizationId,
                moduleId: input.moduleId,
                formId: input.formId,
                recordId: input.recordId,
                tableName: input.tableName,
                platform: 'web',
                contextType: input.contextType,
                caller: input.caller,
              },
            })
            .pipe(
              map((res) => {
                if (res?.actions?.data && res?.actions?.data?.length > 0) {
                  return filterAndSortActions(res.actions.data as IAction[]);
                }
                return [];
              }),
            )
        );
      }),
    );
  }

  async openFormDialog(action: IAction, onActionResult: OnActionResult): Promise<void> {
    const config = action.config as FormActionConfig;
    const dialog = (config?.dialog ?? {}) as ActionDialogConfig;

    if (!config || !config?.formId || !config.formMode) {
      throw new Error('Missing form config properties');
    }

    const translation = await firstValueFrom(
      combineLatest([
        tr(config.formTitle ?? ''),
        tr(dialog.title ?? 'Form'),
        tr(dialog.confirmText ?? 'Confirm'),
        tr(dialog.cancelText ?? 'Cancel'),
        tr(action.successMessage ?? 'action_success'),
        tr(action.errorMessage ?? 'action_error'),
      ]).pipe(
        map(([formTitle, dialogTitle, dialogSubmitBtn, dialogCancelBtn, actionSuccessMessage, actionErrorMessage]) => ({
          formTitle,
          dialogTitle,
          dialogSubmitBtn,
          dialogCancelBtn,
          actionSuccessMessage,
          actionErrorMessage,
        })),
      ),
    );

    this.formDialogService.openForm({
      formId: config.formId,
      formMode: config.formMode,
      allowNullishQueryData: config.allowNullishQueryData,
      formrecordId: config.recordId,
      parentFormId: config.parentRecordId,
      dialogTitle: translation.dialogTitle,
      dialogIcon: action.icon ?? 'description',
      initialFormState: config.initalFormState,
      prefillData: config.prefillData && Object.keys(config.prefillData).length > 0 ? config.prefillData : undefined,
      prefilledGqlVariables:
        config.prefilledGqlVariables && Object.keys(config.prefilledGqlVariables).length > 0 ? config.prefilledGqlVariables : undefined,
      declineButtonTitle: translation.dialogCancelBtn,
      submitButtonTitle: translation.dialogSubmitBtn,
      formTitle: translation.formTitle,
      width: dialog.width,
      height: dialog.height,
      formDialogActions: config.formDialogActions,
      onSubmitSuccessAction: async (data) => {
        this.messageService.showInfoSnackbar(translation.actionSuccessMessage);
        await this.onAfterSuccessAction(action);
        onActionResult({ action: action, success: true, result: { response: data } });
      },
      onSubmitErrorAction: (error) => {
        this.messageService.showErrorSnackbar(translation.actionErrorMessage, `${error ?? 'Unknown error'}`);
      },
    });
  }

  async openFormAction(input: IOpenFormInput, dialogId: string): Promise<void> {
    const { formId, recordId, title, parentId } = input;

    if (!formId || !recordId) {
      this.messageService.showErrorSnackbar(
        `Open form error: No input parametrs for form id or record id. FormId: ${formId}, recordId: ${recordId}`,
      );
      return;
    }
    try {
      const tenantId = this.actionsUtils.getAuthInfo().tenant.id;
      const isEndUser = await this.actionsUtils.isEndUser();
      const formMode: FormMode =
        isEndUser &&
        ![
          TenantId.InterexportLocalIt,
          TenantId.InterexportMedicina,
          TenantId.InterexportRadiologija,
          TenantId.InterexportKm,
          TenantId.Halcom,
          TenantId.HalcomBA,
          TenantId.HalcomRS,
          TenantId.HalcomCC,
        ].includes(tenantId)
          ? 'readOnly'
          : 'update';
      //  upoštevaj?: isEndUser || communicationIsEu === true

      const dialogTitle = await firstValueFrom(tr(title));

      await lastValueFrom(
        this.formDialogService.openFormWithResult(
          {
            formId: formId,
            formrecordId: recordId,
            parentFormId: parentId,
            formMode: formMode,
            dialogTitle: dialogTitle,
            onSubmitErrorAction: (error) => {
              this.messageService.showErrorSnackbar('Save form error', `${error ?? 'Unknown error'}`);
            },
          },
          dialogId,
        ),
      );
    } catch (error) {
      this.messageService.showErrorSnackbar('Open form error', error);
    }
  }
}
