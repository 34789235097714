import { gql } from '@apollo/client/core';

export const knowledgebaseSearchQuery = gql`
  query knowledgebase_search_query($tenant_id: bigint!, $search: String!) {
    servicedesk_search_knowledge_base(args: { p_tenant_id: $tenant_id, search: $search }, limit: 5) {
      id
      question(path: "sl_SI")
      answer
    }
  }
`;

export const knowledgebaseQuery = gql`
  query knowledgebase_query($id: bigint!, $plain_path: String!, $html_path: String!) {
    servicedesk_knowledge_base_by_pk(id: $id) {
      question(path: "sl_SI")
      plain: answer(path: $plain_path)
      html: answer(path: $html_path)
    }
  }
`;
