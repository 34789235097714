import { gql } from "@apollo/client/core";

export const phoneExtensionsSubscription = gql`
  subscription PhoneExtensionSubscription(
    $user_ids: [Int!]
    $organization_id: bigint
  ) {
    data: telephony_extensions(
      where: {
        user_id: { _in: $user_ids }
        organization_id: { _eq: $organization_id }
      }
    ) {
      id
      user_id
      organization_id
      server_id
      name
      email
      status
      status_type
      register
      queues
      queue_status
    }
  }
`;

export const phoneCallSubscription = gql`
  subscription PhoneCallsSubscription(
    $limit: Int
    $organization_id: bigint
    $extensions: [String!]
  ) {
    data: telephony_phone_call(
      where: {
        _and: [
          { organization_id: { _eq: $organization_id } }
          {
            _or: [
              { caller: { _in: $extensions } }
              { reciever: { _in: $extensions } }
            ]
          }
          { status: { _in: ["Connected", "Ringing"] } }
        ]
      }
      order_by: { updated_at: desc, organization_id: desc, call_id: desc }
      distinct_on: [updated_at, organization_id, call_id]
      limit: $limit
    ) {
      id
      organization_id
      server_id
      call_id
      call_connection_id
      history_id_of_the_call
      contact_id
      contact_name
      agent_name
      caller
      reciever
      is_inbound
      is_outbound
      status
      start_ringing
      start_talking
      no_of_missed_calls
      queue_ref {
        name
        tags
      }
    }
  }
`;

export const dismissPhoneCallMutation = gql`
  mutation DismissPhoneCallMutation($id: bigint!) {
    data: update_telephony_phone_call(
      where: { id: { _eq: $id } }
      _set: { is_deleted: true }
    ) {
      affected_rows
    }
  }
`;

export const phoneCallMutation = gql`
  mutation ThreecxActionMutation(
    $organization_id: bigint
    $call_id: Int
    $call_connection_id: Int
    $extension: String!
    $dial_to: String!
    $action: String!
    $serverId: Int!
  ) {
    data: insert_telephony_actions(
      objects: [
        {
          organization_id: $organization_id
          call_id: $call_id
          call_connection_id: $call_connection_id
          ext: $extension
          dial_to: $dial_to
          action: $action
          server_id: $serverId
        }
      ]
    ) {
      affected_rows
    }
  }
`;

export const updatePhoneCallNotesMutation = gql`
  mutation UpdateCallNotesMutation($id: bigint!, $notes: String) {
    data: update_telephony_phone_call(
      where: { id: { _eq: $id } }
      _set: { notes: $notes }
    ) {
      affected_rows
    }
  }
`;

export const activeUsersQuery = gql`
  query activeUsersQuery($orgId: bigint!) {
    telephony_extensions(
      where: {
        organization_id: { _eq: $orgId }
        _and: {
          status: { _eq: "Available" }
          _and: { user_id: { _is_null: false } }
        }
      }
    ) {
      id
      name
      email
      status
      user_id
    }
  }
`;

export const linkPhoneCallMutation = gql`
mutation LinkPhoneCallMutation(
  $call_connection_id: Int!,
  $call_id: Int!,
  $history_id_of_the_call: String!,
  $server_id: Int!,
  $link_to_record: jsonb!
) {
  update_telephony_phone_call_by_pk(
    pk_columns: {
      call_connection_id: $call_connection_id,
      call_id: $call_id,
      history_id_of_the_call: $history_id_of_the_call,
      server_id: $server_id
    },
    _append: {
      link_to_record: $link_to_record
    }
  ) {
    __typename
  }
}
`;
